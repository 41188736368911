import { IOrgLookupType } from '../../_shared/enums/enums';
import { IOrgConfig } from '../../_shared/models/models-index';

export class OrgConfig implements IOrgConfig {

  orgLookupTypes: Record<string, IOrgLookupType> = {
    'national': { name: 'National', orgLookupTypeId: 1, default: true, orgEntityType: 'national' },
    'maco': { name: 'MACO', orgLookupTypeId: 2, orgEntityType: 'national' },
    'dealergroup': { name: 'Dealer Groups', orgLookupTypeId: 4, orgEntityType: 'national' }
  };

  public nationalOrgLookupTypeIds: number[] = [1,2,4];
  public lmaOrgLookupTypeIds: number[] = [];
  public virtual20OrgLookupTypeIds: number[] = [];
  public dealerGroupOrgLookupTypeIds: number[] = [];

  orgEntityType: Map<string, string> = new Map([
    ['orgcode2', 'orgcode2'],
    ['orgcode1', 'orgcode1'],
    ['dealer', 'dealer'],
  ]);

  entityTypeName: Map<string, string> = new Map([
    ['orgcode2', 'Region'],
    ['orgcode1', 'District'],
    ['dealer', 'Dealer'],
  ]);
}
