import {
    IDefaultTableColumnConfig,
    IWebsiteVehicleConfig,
    IMultiLevelDualAxisLineGraphConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteVehicleConfig implements IWebsiteVehicleConfig {
    reportTitle = 'Vehicle Summary';

    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Summary Data',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.websiteVdpSummaryHelp,
        helpTextTitle: 'VDP Summary',
        exportName: 'VDP Summary - Summary Data',
        metricDisplayModes: ['POP', 'YOY']
    };

      public metricDisplayModes = [
        { name: 'POP', value: 'MOM' },
        { name: 'YOY', value: 'YOY' }
      ];

    public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [
            {
                id: Enums.websiteMetrics.newVdpViews.metricId,
                displayName: Enums.websiteMetrics.newVdpViews.name,
                propertyName: Enums.websiteMetrics.newVdpViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.usedVdpViews.metricId,
                displayName: Enums.websiteMetrics.usedVdpViews.name,
                propertyName: Enums.websiteMetrics.usedVdpViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.cpoVdpViews.metricId,
                displayName: Enums.websiteMetrics.cpoVdpViews.name,
                propertyName: Enums.websiteMetrics.cpoVdpViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // {
            //     id: Enums.websiteMetrics.otherVdpViews.metricId,
            //     displayName: Enums.websiteMetrics.otherVdpViews.name,
            //     propertyName: Enums.websiteMetrics.otherVdpViews.nameKey,
            //     metricFormatKey: constants.formatKeys.localeString
            // },
            {
                id: Enums.websiteMetrics.totalVdpViews.metricId,
                displayName: Enums.websiteMetrics.totalVdpViews.name,
                propertyName: Enums.websiteMetrics.totalVdpViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
        ],
        childMetrics: [], // this needs to be data driven

        defaultChildrenIds: [ 'All Models', 'All Models' ],
        defaultParentIds: [ Enums.websiteMetrics.newVdpViews.metricId, Enums.websiteMetrics.usedVdpViews.metricId ],
        metricOneColorHexCode: '#4875b4',
        metricTwoColorHexCode: '#00aced'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Vehicle Model',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayNameNormalCase,
            clickable: false
        },

        // New VDPs
        {
            show: true,
            header: 'New VDP Views',
            columnDef: 'newVdpViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Prev. Period New VDP Views',
            columnDef: 'newVdpViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'New VDP Views POP',
            columnDef: 'newVdpViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Prev. Year New VDP Views',
            columnDef: 'newVdpViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'New VDP Views YOY',
            columnDef: 'newVdpViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        // Used VDPs
        {
            show: true,
            header: 'Used VDP Views',
            columnDef: 'usedVdpViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Prev. Period Used VDP Views',
            columnDef: 'usedVdpViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Used VDP Views POP',
            columnDef: 'usedVdpViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Prev. Year Used VDP Views',
            columnDef: 'usedVdpViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Used VDP Views YOY',
            columnDef: 'usedVdpViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        // CPO VDPs
        {
            show: true,
            header: 'CPO VDP Views',
            columnDef: 'cpoVdpViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Prev. Period CPO VDP Views',
            columnDef: 'cpoVdpViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString

        },
        {
            show: false,
            header: 'CPO VDP Views POP',
            columnDef: 'cpoVdpViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Prev. Year CPO VDP Views',
            columnDef: 'cpoVdpViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'CPO VDP Views YOY',
            columnDef: 'cpoVdpViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        // Other VDPs
        // {
        //     show: true,
        //     header: 'Other VDP Views',
        //     columnDef: 'otherVdpViews',
        //     isMom: false,
        //     isYoy: false,
        //     metricFormatKey: constants.formatKeys.localeString
        // },
        // {
        //     show: false,
        //     header: 'Prev. Period Other VDP Views',
        //     columnDef: 'otherVdpViewsPreviousMTD',
        //     isMom: true,
        //     isYoy: false,
        //     metricFormatKey: constants.formatKeys.localeString
        // },
        // {
        //     show: false,
        //     header: 'Used VDP Views POP',
        //     columnDef: 'otherVdpViewsMOM',
        //     isMom: true,
        //     isYoy: false,
        //     metricFormatKey: constants.formatKeys.percentageTwoDecimals
        // },
        // {
        //     show: false,
        //     header: 'Prev. Year Other VDP Views',
        //     columnDef: 'otherVdpViewsPreviousYear',
        //     isMom: false,
        //     isYoy: true,
        //     metricFormatKey: constants.formatKeys.localeString
        // },
        // {
        //     show: false,
        //     header: 'Other VDP Views YOY',
        //     columnDef: 'otherVdpViewsYOY',
        //     isMom: false,
        //     isYoy: true,
        //     metricFormatKey: constants.formatKeys.percentageTwoDecimals
        // },
        // Total VDPs
        {
            show: true,
            header: 'Total VDP Views',
            columnDef: 'totalVdpViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Prev. Period Total VDP Views',
            columnDef: 'totalVdpViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Total VDP Views POP',
            columnDef: 'totalVdpViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Prev. Year Total VDP Views',
            columnDef: 'totalVdpViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Total VDP Views YOY',
            columnDef: 'totalVdpViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
    ];
}
