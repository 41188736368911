import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
  NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
  NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
  dateModeOptions = [
    Enums.dateModes.currentMonth,
    Enums.dateModes.lastMonth,
    Enums.dateModes.lastThirtyDays,
    Enums.dateModes.previousThreeMonths,
    Enums.dateModes.previousTwelveMonths
  ]
  showEntireOrgHierarchyForAllUsers = false;
  defaultMonthDateMode: DateMonthModes = 'calendar';
  filterTypeLookup: { [id: string]: FilterType } = {
    'org': { displayName: 'Hierarchy', name: 'org' },
    'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
    'device-type': { displayName: 'Device Type', name: 'device-type' },
    'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
    'website-provider': { displayName: 'Provider', name: 'website-provider' },
  };

  filterReportConfigs: { [id: string]: FilterConfigReport } = {
    // Chat
    'ChatOverview': {
      dateMode: Enums.dateModes.currentMonth,
      //restrictDates: true,
      reportName: constants.reportNames.chatOverview,
      filters: ['org', 'device-type'],
      defaults: ['org'],
      section: 'provider-tools',
      orgTypes: [1,2,4]
    },
    'DigitalRetailingOverview': {
      reportName: constants.reportNames.digitalRetailingOverview,
      dateMode: Enums.dateModes.currentMonth,
      filters: ['org', 'digital-retailing-provider', 'device-type'],
      defaults: ['org'],
      section: 'provider-tools',
      orgTypes: [1,2,4]
    },
    // Website
    'WebsiteOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.websiteOverview,
      filters: ['org'],
      defaults: ['org'],
      section: 'website',
      orgTypes: [1,2,4]
    },
    'WebsiteScorecard': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.websiteScorecard,
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org', 'website-provider'
      ],
      section: 'website',
      orgTypes: [1,2,4]
    },
    'WebsiteSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteSummary,
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org', 'website-provider'
      ],
      section: 'website',
      orgTypes: [1,2,4]
    },
    'WebsiteReferrerSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteReferrerSummary,
      filters: [
        'org', 'device-type'
      ],
      defaults: [
        'org'
      ],
      section: 'website',
      orgTypes: [1,2,4]
    },
    'WebsiteVehicleSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteVehicleSummary,
      filters: [
        'org', 'website-provider', 'device-type'
      ],
      defaults: [
        'org', 'website-provider'
      ],
      section: 'website',
      orgTypes: [1,2,4]
    },
    'WebsiteProviderSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteProviderSummary, // org only
      filters: ['org'],
      defaults: ['org'],
      section: 'website',
      orgTypes: [1,2,4]
    },
  };

  FILTER_CONFIG: { [id: string]: Filter } = {
    'org': {
      type: 'org',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'chat-provider': {
      type: 'chat-provider',
      available: [2, 5, 12, 15, 17, 18, 19, 41],
      selected: [
        { value: 2, display: 'DealerInspire' },
        { value: 5, display: 'Dealer eProcess' },
        { value: 15, display: 'ActivEngage' },
        { value: 12, display: 'CarNow' },
        { value: 18, display: 'LivePerson' },
        { value: 17, display: 'Gubagoo' },
        { value: 19, display: 'Podium' },
        { value: 41, display: 'Kenect' }
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'device-type': {
      type: 'device-type',
      selected: [
        { value: 1, display: 'Desktop' },
        { value: 2, display: 'Tablet' },
        { value: 3, display: 'Phone' }
      ],
      removable: true,
      lockable: true,
      locked: false
    },
    'digital-retailing-provider': {
      type: 'digital-retailing-provider',
      available: [4, 12, 38, 39],
      selected: [
        { value: 12, display: 'CarNow' },
        { value: 38, display: 'Darwin' },
        { value: 4, display: 'Dealer.com' },
        { value: 39, display: 'Roadster' }
      ],

      removable: true,
      lockable: true,
      locked: false
    },
    'website-provider': {
      type: 'website-provider',
      available: [2, 3, 4, 5, 32],
      selected: [
        { value: 2, display: 'DealerInspire' },
        { value: 3, display: 'DealerON' },
        { value: 4, display: 'Dealer.com' },
        { value: 5, display: 'Dealer eProcess' },
        { value: 32, display: 'Team Velocity' },
        //{ value: 21, display: 'eBizAutos' },
        //{ value: 37, display: 'Sincro' }
      ],
      removable: false,
      lockable: true,
      locked: false
    }
  };
}
