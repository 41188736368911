import {
    IDefaultTableColumnConfig,
    IDigitalRetailingOverviewBillboardConfig,
    IDigitalRetailingOverviewConfig,
    IDualAxisLineGraphConfig,

    IMultiLevelDualAxisLineGraphConfig
  } from '../../../../_shared/models/models-index';
  import * as constants from '../../../../_shared/constants/constants';
  import { Enums } from '../../../../_shared/enums/enums';

  export class DigitalRetailingOverviewConfig implements IDigitalRetailingOverviewConfig {
    public useV5 = false;
    public useDefaultComparisonColumns = false;
    public metricDisplayModes = [
      { name: 'POP', value: 'MOM' },
      { name: 'YOY', value: 'YOY' }
    ];
    public previousDateColumnDisplayOverrides = {
      previousMonthDisplay: 'Prev. Period',
      momDisplay: 'POP'
    }
    public reportTitle = 'Digital Retailing Overview';
    public dealerTableLabel = 'Dealer Summary';
    public showEngagementAnalysis = false;
    public chartSeriesColors = ['#384752', '#52616C', '#05141F'];
    public billboards: IDigitalRetailingOverviewBillboardConfig[] = [{
      title: 'Session Start Rate',
      subtitle: null,
      iconClass: 'fa-users fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'sessionStartRate',
      metricMOMPropertyName: 'sessionStartRateMOM',
      metricYOYPropertyName: 'sessionStartRateYOY',
      metricPreviousMonthPropertyName: 'sessionStartRatePreviousMonth',
      metricPreviousMTDPropertyName: 'sessionStartRatePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Peroid',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'POP',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'sessionStartRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'DigitalRetailingOverviewSessionStartRateBillboardHelp',
      helpTextTitle: 'Session Start Rate',
    }, {
      title: 'Payment Calc Rate',
      subtitle: null,
      iconClass: 'fa-calculator fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'paymentCalcRate',
      metricMOMPropertyName: 'paymentCalcRateMOM',
      metricYOYPropertyName: 'paymentCalcRateYOY',
      metricPreviousMonthPropertyName: 'paymentCalcRatePreviousMonth',
      metricPreviousMTDPropertyName: 'paymentCalcRatePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Period',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'POP',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'paymentCalcRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'DigitalRetailingOverviewPaymentCalcRateBillboardHelp',
      helpTextTitle: 'Payment Calc Rate',
    }, {
      title: 'Lead Capture Rate',
      subtitle: null,
      iconClass: 'fa-user-circle fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadCaptureRate',
      metricMOMPropertyName: 'leadCaptureRateMOM',
      metricYOYPropertyName: 'leadCaptureRateYOY',
      metricPreviousMonthPropertyName: 'leadCaptureRatePreviousMonth',
      metricPreviousMTDPropertyName: 'leadCaptureRatePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Period',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'POP',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'leadCaptureRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'DigitalRetailingOverviewLeadCaptureRateBillboardHelp',
      helpTextTitle: 'Lead Capture Rate',
    }, {
      title: 'Appointment Rate',
      subtitle: null,
      iconClass: 'fa-check-square-o fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'appointmentRate',
      metricMOMPropertyName: 'appointmentRateMOM',
      metricYOYPropertyName: 'appointmentRateYOY',
      metricPreviousMonthPropertyName: 'appointmentRatePreviousMonth',
      metricPreviousMTDPropertyName: 'appointmentRatePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Period',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'POP',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'appointmentRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'DigitalRetailingOverviewAppointmentRateBillboardHelp',
      helpTextTitle: 'Appointment Rate',
    }];
    public kpiSheetOptions = {
      overview: [{
        name: 'providerId',
        label: 'Provider',
        isHeader: true
      }, {
        name: 'uniqueShoppers',
        label: 'Unique Shoppers',
      }, {
        name: 'leadCaptureRate',
        label: 'Lead Capture Rate',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'paymentCalcRate',
        label: 'Payment Calc Rate',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'tradeInRate',
        label: 'Trade-In Rate',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'allCreditAppRate',
        label: 'All Credit App Rate',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'appointmentRate',
        label: 'Appointment Rate',
        format: constants.formatKeys.percentageTwoDecimals
      }]
    };
    public trendGraphConfig: IMultiLevelDualAxisLineGraphConfig = {
      xAxisPropertyName: 'date',
      parentMetrics: [
        {
          id: Enums.digitalRetailingMetrics.visits.metricId,
          displayName: 'DR Visits',
          propertyName: Enums.digitalRetailingMetrics.visits.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          id: Enums.digitalRetailingMetrics.visitors.metricId,
          displayName: 'DR Visitors',
          propertyName: Enums.digitalRetailingMetrics.visitors.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          id: Enums.digitalRetailingMetrics.uniqueShoppers.metricId,
          displayName: Enums.digitalRetailingMetrics.uniqueShoppers.name,
          propertyName: Enums.digitalRetailingMetrics.uniqueShoppers.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          id: Enums.digitalRetailingMetrics.sessionStarts.metricId,
          displayName: Enums.digitalRetailingMetrics.sessionStarts.name,
          propertyName: Enums.digitalRetailingMetrics.sessionStarts.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          id: Enums.digitalRetailingMetrics.sessionStartRate.metricId,
          displayName: Enums.digitalRetailingMetrics.sessionStartRate.name,
          propertyName: Enums.digitalRetailingMetrics.sessionStartRate.nameKey,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
          id: Enums.digitalRetailingMetrics.paymentCalculators.metricId,
          displayName: Enums.digitalRetailingMetrics.paymentCalculators.name,
          propertyName: Enums.digitalRetailingMetrics.paymentCalculators.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          id: Enums.digitalRetailingMetrics.paymentCalculatorRate.metricId,
          displayName: Enums.digitalRetailingMetrics.paymentCalculatorRate.name,
          propertyName: Enums.digitalRetailingMetrics.paymentCalculatorRate.nameKey,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
          id: Enums.digitalRetailingMetrics.tradeIns.metricId,
          displayName: Enums.digitalRetailingMetrics.tradeIns.name,
          propertyName: Enums.digitalRetailingMetrics.tradeIns.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          id: Enums.digitalRetailingMetrics.tradeInRate.metricId,
          displayName: Enums.digitalRetailingMetrics.tradeInRate.name,
          propertyName: Enums.digitalRetailingMetrics.tradeInRate.nameKey,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
          id: Enums.digitalRetailingMetrics.financeFinishes.metricId,
          displayName: 'F & I Completes',
          propertyName: Enums.digitalRetailingMetrics.financeFinishes.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          id: Enums.digitalRetailingMetrics.financeFinishRate.metricId,
          displayName: 'F & I Complete Rate',
          propertyName: Enums.digitalRetailingMetrics.financeFinishRate.nameKey,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
          id: Enums.digitalRetailingMetrics.leadCaptures.metricId,
          displayName: Enums.digitalRetailingMetrics.leadCaptures.name,
          propertyName: Enums.digitalRetailingMetrics.leadCaptures.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          id: Enums.digitalRetailingMetrics.leadCaptureRate.metricId,
          displayName: Enums.digitalRetailingMetrics.leadCaptureRate.name,
          propertyName: Enums.digitalRetailingMetrics.leadCaptureRate.nameKey,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
          id: Enums.digitalRetailingMetrics.allCreditApps.metricId,
          displayName: Enums.digitalRetailingMetrics.allCreditApps.name,
          propertyName: Enums.digitalRetailingMetrics.allCreditApps.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          id: Enums.digitalRetailingMetrics.allCreditAppRate.metricId,
          displayName: Enums.digitalRetailingMetrics.allCreditAppRate.name,
          propertyName: Enums.digitalRetailingMetrics.allCreditAppRate.nameKey,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
          id: Enums.digitalRetailingMetrics.preQualifieds.metricId,
          displayName: Enums.digitalRetailingMetrics.preQualifieds.name,
          propertyName: Enums.digitalRetailingMetrics.preQualifieds.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          id: Enums.digitalRetailingMetrics.preQualifiedRate.metricId,
          displayName: Enums.digitalRetailingMetrics.preQualifiedRate.name,
          propertyName: Enums.digitalRetailingMetrics.preQualifiedRate.nameKey,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
          id: Enums.digitalRetailingMetrics.creditApps.metricId,
          displayName: Enums.digitalRetailingMetrics.creditApps.name,
          propertyName: Enums.digitalRetailingMetrics.creditApps.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          id: Enums.digitalRetailingMetrics.creditAppRate.metricId,
          displayName: Enums.digitalRetailingMetrics.creditAppRate.name,
          propertyName: Enums.digitalRetailingMetrics.creditAppRate.nameKey,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
          id: Enums.digitalRetailingMetrics.appointments.metricId,
          displayName: Enums.digitalRetailingMetrics.appointments.name,
          propertyName: Enums.digitalRetailingMetrics.appointments.nameKey,
          metricFormatKey: constants.formatKeys.localeString
        }, {
          id: Enums.digitalRetailingMetrics.appointmentRate.metricId,
          displayName: Enums.digitalRetailingMetrics.appointmentRate.name,
          propertyName: Enums.digitalRetailingMetrics.appointmentRate.nameKey,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        }
      ],
      childMetrics: [
        {
          id: Enums.chatProviders.allProviders.providerId,
          displayName: Enums.chatProviders.allProviders.displayName,
          propertyName: Enums.chatProviders.allProviders.name,
          metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
          id: 4,
          displayName: 'Dealer.com',
          propertyName: 'Dealer.com',
          metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
          id: 12,
          displayName: 'CarNow',
          propertyName: 'CarNow',
          metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
          id: 38,
          displayName: 'Darwin',
          propertyName: 'Darwin',
          metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
          id: 39,
          displayName: 'Roadster',
          propertyName: 'Roadster',
          metricFormatKey: constants.formatKeys.entityDisplayName
        },
      ],
      defaultChildrenIds: [Enums.chatProviders.allProviders.providerId, Enums.chatProviders.allProviders.providerId],
      defaultParentIds: [Enums.digitalRetailingMetrics.sessionStarts.metricId, Enums.digitalRetailingMetrics.appointmentRate.metricId],
      metricOneColorHexCode: '#4875b4',
      metricTwoColorHexCode: '#00aced'
    };
    public dealerTableColumns: IDefaultTableColumnConfig[] = [
      {
        show: true,
        header: '',
        columnDef: 'entity',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.entityDisplayName,
        clickable: true
      }, {
        show: false,
        print: true,
        header: 'Dealer Name',
        columnDef: 'displayName',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
      },
      // website Visits
      {
        show: true,
        header: 'DR Visits',
        columnDef: 'visits',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      }, {
        show: false,
        header: 'DR Visits Prev. Period',
        columnDef: 'visitsPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      }, {
        show: false,
        header: 'DR Visits POP',
        columnDef: 'visitsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // website Visitors
      {
        show: true,
        header: 'DR Visitors',
        columnDef: 'visitors',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      }, {
        show: false,
        header: 'DR Visitors Prev. Period',
        columnDef: 'visitorsPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      }, {
        show: false,
        header: 'DR Visitors POP',
        columnDef: 'visitorsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Unique Shoppers
      {
        show: true,
        header: 'Unique Shoppers',
        columnDef: 'uniqueShoppers',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      }, {
        show: false,
        header: 'Unique Shoppers Prev. Period',
        columnDef: 'uniqueShoppersPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      }, {
        show: false,
        header: 'Unique Shoppers POP',
        columnDef: 'uniqueShoppersMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Session Starts
      {
        show: true,
        header: 'Session Starts',
        columnDef: 'sessionStarts',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      }, {
        show: false,
        header: 'Session Starts Prev. Period',
        columnDef: 'sessionStartsPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      }, {
        show: false,
        header: 'Session Starts POP',
        columnDef: 'sessionStartsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Session Start Rate
      {
        show: true,
        header: 'Session Start Rate',
        columnDef: 'sessionStartRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Session Start Rate Prev. Period',
        columnDef: 'sessionStartRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Session Start Rate POP',
        columnDef: 'sessionStartRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Payment Calculators
      {
        show: true,
        header: 'Payment Calculators',
        columnDef: 'paymentCalcs',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      }, {
        show: false,
        header: 'Payment Calculators Prev. Period',
        columnDef: 'paymentCalcsPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      }, {
        show: false,
        header: 'Payment Calculators POP',
        columnDef: 'paymentCalcsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Payment Calculator Rate
      {
        show: true,
        header: 'Payment Calculator Rate',
        columnDef: 'paymentCalcRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Payment Calculator Rate Prev. Period',
        columnDef: 'paymentCalcRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Payment Calculator Rate POP',
        columnDef: 'paymentCalcRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Trade-Ins
      {
        show: true,
        header: 'Trade-Ins',
        columnDef: 'tradeIns',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'Trade-Ins Prev. Period',
        columnDef: 'tradeInsPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'Trade-Ins POP',
        columnDef: 'tradeInsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Trade-In Rate
      {
        show: true,
        header: 'Trade-In Rate',
        columnDef: 'tradeInRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Trade-In Rate Prev. Period',
        columnDef: 'tradeInRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Trade-In Rate POP',
        columnDef: 'tradeInRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // F & I Completions
      {
        show: true,
        header: 'F & I Completes',
        columnDef: 'financeFinishes',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'F & I Completes Prev. Period',
        columnDef: 'financeFinishesPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'F & I Completes POP',
        columnDef: 'financeFinishesMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // F & I Completions Rate
      {
        show: true,
        header: 'F & I Complete Rate',
        columnDef: 'financeFinishRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'F & I Complete Rate Prev. Period',
        columnDef: 'financeFinishRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'F & I Complete Rate POP',
        columnDef: 'financeFinishRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Lead Captures
      {
        show: true,
        header: 'Lead Captures',
        columnDef: 'leadCaptures',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'Lead Captures Prev. Period',
        columnDef: 'leadCapturesPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'Lead Captures POP',
        columnDef: 'leadCapturesMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Lead Capture Rate
      {
        show: true,
        header: 'Lead Capture Rate',
        columnDef: 'leadCaptureRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Lead Capture Rate Prev. Period',
        columnDef: 'leadCaptureRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Lead Capture Rate POP',
        columnDef: 'leadCaptureRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // All Credit Apps
      {
        show: true,
        header: 'All Credit Apps',
        columnDef: 'allCreditApps',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'All Credit Apps Prev. Period',
        columnDef: 'allCreditAppsPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'All Credit Apps POP',
        columnDef: 'allCreditAppsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // All Credit App Rate
      {
        show: true,
        header: 'All Credit Apps Rate',
        columnDef: 'allCreditAppRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'All Credit Apps Rate Prev. Period',
        columnDef: 'allCreditAppRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'All Credit Apps Rate POP',
        columnDef: 'allCreditAppRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Pre-Qualified
      {
        show: true,
        header: 'Pre-Qualified',
        columnDef: 'preQualified',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'Pre-Qualified Prev. Period',
        columnDef: 'preQualifiedPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'Pre-Qualified POP',
        columnDef: 'preQualifiedMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Pre-Qualified Rate
      {
        show: true,
        header: 'Pre-Qualified Rate',
        columnDef: 'preQualifiedRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Pre-Qualified Rate Prev. Period',
        columnDef: 'preQualifiedRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Pre-Qualified Rate POP',
        columnDef: 'preQualifiedRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Credit Apps
      {
        show: true,
        header: 'Credit Apps',
        columnDef: 'creditApps',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'Credit Apps Prev. Period',
        columnDef: 'creditAppsPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'Credit Apps POP',
        columnDef: 'creditAppsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Credit App Rate
      {
        show: true,
        header: 'Credit App Rate',
        columnDef: 'creditAppRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Credit App Rate Prev. Period',
        columnDef: 'creditAppRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Credit App Rate POP',
        columnDef: 'creditAppRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Appointments
      {
        show: true,
        header: 'Appointments',
        columnDef: 'appointments',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'Appointments Prev. Period',
        columnDef: 'appointmentsPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false
      },
      {
        show: false,
        header: 'Appointments POP',
        columnDef: 'appointmentsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
      // Appointment Rate
      {
        show: true,
        header: 'Appointment Rate',
        columnDef: 'appointmentRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Appointment Rate Prev. Period',
        columnDef: 'appointmentRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: false,
        header: 'Appointment Rate POP',
        columnDef: 'appointmentRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal,
        clickable: false
      },
    ];
  }
