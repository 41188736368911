import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, tap, withLatestFrom, take, switchMap } from 'rxjs/operators';
import { AppState, ConfigState } from '../../_store/app-state.model';
import { AppSelectors } from '../../_store/selector-types';
import { Enums, IOrgLookupType } from '../enums/enums';
import * as Models from '../models/models-index';
import * as FilterPanelTypes from './../sd-filter-panel-module/types/types';
import * as FilterModelTypes from './filter.model';
import { FilterSelectors } from './store/selector-types';
import { FilterActions } from './store/action-types';
import * as moment from 'moment';
import { FilterOptions, ReportViewFilterSelection } from './store';
import { environment } from '../../../environments/environment';
import { ConfigurationService } from '../services/config/config.service';
import { Filter, FilterTypes } from './filter.model';
import { FilterDateService, DateRangeSet } from './filter-date.service';
import { IFilterModel, ReportFilterRequestSelection } from '../models/models-index';
import { SharedTranslationService } from '../locale/translation/shared-translation.service'
import { LocaleService } from '../locale/locale.service';
import * as constants from '../constants/constants';
import { HttpClient } from '@angular/common/http';
import { TypeCheck } from '../hy-filter-panel-module/Utils';
import { request } from 'http';
import { IFacetedFilterSelection, ISelectionInfo } from '../hy-filter-panel-module/types';

@Injectable({ providedIn: 'root' })
export class FilterBarService {
  reportName$: Observable<string>;
  user: Models.IAuthenticationInfo;
  config: ConfigState;
  filterLoadRequested: Set<string> = new Set<string>();
  locale: string;

  constructor(
    private store$: Store<AppState>,
    private configService: ConfigurationService,
    private filterDateService: FilterDateService,
    private sharedTranslationService: SharedTranslationService,
    private localeService: LocaleService,
    private http: HttpClient
  ) {
    this.reportName$ = this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
      filter(data => !!data?.reportName),
      map(data => data.reportName)
    );
    this.localeService.locale$.pipe(tap(locale => this.locale = locale)).subscribe();
  }


  labelTranslator = (val) => this.sharedTranslationService.getLabelTranslation(val, this.locale);
  pageFilterNodeFormatter: (node: FilterPanelTypes.IFilterTreeNode) => string = (node) => node.display;

  clearFiltersLoaded() {
    this.filterLoadRequested.clear();
  }

  getReportFilters(reportName: string) {
    return combineLatest([
      this.store$.select(FilterSelectors.selectReportFilters, reportName),
      this.store$.select(FilterSelectors.selectLockedFilters),
      this.filterDateService.dateRanges$,
      this.store$.select(AppSelectors.selectConfigState).pipe(filter(config => config.orgLookups.length > 0)),
    ]).pipe(
      tap(([filters, _]) => {
        if (!filters) {
          if (!this.filterLoadRequested.has(reportName)) {
            this.store$.dispatch(FilterActions.initializeFilters({ reportName }));
            this.filterLoadRequested.add(reportName);
          }
        }
      }),
      filter(([filters, _]) => !!filters && this.isFilterSetValid(filters, reportName)),
      map(([filters, lockedFilters, dateRanges]) => {
        const resolved = this.resolveFilters(reportName, filters, lockedFilters, dateRanges);
        return resolved;
      })
    );
  }

  getInitialReportFilters(reportName: string) {
    return this.store$.select(FilterSelectors.selectFilterState).pipe(
      withLatestFrom(this.getDefaultFilterConfigs().pipe(
        filter(defaultFilters => defaultFilters?.length > 0)
      )),
      withLatestFrom(this.filterDateService.dateRanges$),
      take(1),
      map(([[filterState, defaultFilters], dateRanges]) => {
        const reportConfig = this.configService.filter.filterConfig.filterReportConfigs[reportName];
        const filters: Filter[] = [];

        // determine filters to init
        const filtersToInit = new Set([
          FilterTypes.date, // seems hacky but we always want to make sure date's in there
          ...reportConfig.defaults
          // ...filterState.lockedFilters.filter(lf => reportConfig.filters.includes(lf.type)).map(f => f.type)
        ]);

        // get default date filter
        const defaultDateFilter = defaultFilters.find(f => f.type === FilterTypes.date);

        // get org locked and existing
        const existingOrgFilter = { ...filterState.filters.find(f => f.filters.find(fl => fl.type === FilterTypes.org)) };
        const lockedOrgFilter = filterState.lockedFilters.find(f => f.type === FilterTypes.org);
        // configure default report filters
        filtersToInit.forEach(type => {
          // handle date real special like
          if (type === FilterTypes.date) {
            // check locked filters to see if date is locked and if so, use it
            const lockedDateFilter = filterState.lockedFilters.find(f => f.type === FilterTypes.date);
            // check explicit unlocked filters to see if the date has been unlocked, and unlock default if so
            const explicitUnlockedDateFilter = filterState.explicitUnlockedFilters.includes('date');
            defaultDateFilter.locked = !!explicitUnlockedDateFilter ? false : true;

            // we need to check now to make sure the value for the date fitler is compatible with the report
            filters.push(this.getReportCompatibleDateFilter(reportName, lockedDateFilter || defaultDateFilter, dateRanges));
          } else if (type === FilterTypes.org && (Object.keys(existingOrgFilter).length > 0 || lockedOrgFilter)) {

            const defaultFilter = defaultFilters.find(df => df.type === type) || this.configService.filter.filterConfig.FILTER_CONFIG[type];
            const orgFilter = { ...existingOrgFilter.filters.find(f => f.type === FilterTypes.org) };

            if (!!orgFilter) {
              if (filterState.explicitUnlockedFilters.includes(FilterTypes.org)) {
                orgFilter.selected = defaultFilter.selected;
                orgFilter.locked = false;
                filters.push({ ...orgFilter });
              } else {
                filters.push(this.getReportCompatibleOrgFilter(reportName, lockedOrgFilter));
              }
            } else {
              filters.push({ ...defaultFilter });
            }
          } else {
            const defaultFilter = defaultFilters.find(df => df.type === type) || this.configService.filter.filterConfig.FILTER_CONFIG[type];

            filters.push({ ...defaultFilter });
          }
        });

        const lockedFilters = filters.filter(f => !!f.locked);
        return { filters, lockedFilters };
      }));
  }

  isFilterSetValid(filters: FilterModelTypes.Filter[], reportName: string) {
    const reportConfig = this.configService.filter.filterConfig.filterReportConfigs[reportName];
    const requireOrgFilter = reportConfig.filters.includes('org');

    // check to see that we have a selected date
    const dateFilter = filters.find(f => f.type === 'date');
    if (!dateFilter || dateFilter.selected.length === 0) {
      return false;
    }

    // check to see if report requires org filter and validate it if so
    if (!!requireOrgFilter) {
      // check to see that we have a selected org
      const orgFilter = filters.find(f => f.type === 'org');
      if (!orgFilter || orgFilter.selected.length === 0) {
        return false;
      }
    }

    return true;
  }

  resolveFilters(reportName: string, reportFilters: FilterModelTypes.Filter[], lockedFilters: FilterModelTypes.Filter[], dateRanges: DateRangeSet) {
    if (!lockedFilters || lockedFilters.length === 0) {
      return reportFilters;
    }

    let resolved = reportFilters.map(f => {
      // validate we don't have locked looking filters when we shouldn't
      return <FilterModelTypes.Filter>{ ...f, locked: !!lockedFilters.find(l => l.type === f.type) };
    });
    lockedFilters.forEach(locked => {
      // resolve date
      if (locked.type === FilterTypes.date) {
        locked = this.getReportCompatibleDateFilter(reportName, locked, dateRanges);
      }
      // resolve org
      if (locked.type === FilterTypes.org) {
        locked = this.getReportCompatibleOrgFilter(reportName, locked);
      }
      const existing = resolved.find(f => f.type === locked.type);
      if (existing) {
        const existingIndex = resolved.indexOf(existing);
        resolved.splice(existingIndex, 1, { ...locked });
      } else if (this.isFilterSupported(reportName, locked.type)) {
        resolved = [...resolved, { ...locked }];
      }
    });
    return resolved;
  }

  isFilterSupported(reportName: string, filterType: string) {
    const reportConfig = this.configService.filter.filterConfig.filterReportConfigs[reportName];

    return reportConfig && reportConfig.filters.includes(filterType);
  }

  getReportFilterModel(reportName: string) {
    return this.getReportFilters(reportName).pipe(
      switchMap(filters => this.getDefaultFilterModel().pipe(
        withLatestFrom(this.store$.select(FilterSelectors.selectOptions)),
        map(([filterModel, options]) => {
          return this.getFilterModelFromFilters(reportName, filterModel, filters, options);
        })
      ))
    );
  }

  getAvailableReportFilters(reportName: string) {
    return this.getReportFilters(reportName).pipe(
      filter(filters => !!reportName && !!filters),
      map(filters => {
        // filter from the reports filter set filters already associated with the report
        const availableFilters = this.configService.filter.filterConfig.filterReportConfigs[reportName].filters.filter(
          filterName => !filters.find(f => f.type === filterName)
        );

        // go from names to filter types
        return availableFilters.map(
          availableFilter => this.configService.filter.filterConfig.filterTypeLookup[availableFilter]
        );
      })
    );
  }

  getDefaultFilterModel() {
    return this.store$.select(AppSelectors.selectLoggedInUserAndConfig).pipe(
      switchMap(([user, config]) => this.getOrgLookupFilterNodes().pipe(
        withLatestFrom(this.filterDateService.dateRanges$),
        map(([userOrgFilterTreeNodes, dateRangeSet]) => {
          const defaultDates = dateRangeSet.months[0];
          const defaultFilter: Models.IFilterModel = {
            roleLevel: user.role,
            roleEntity: user.roleEntity,
            roleId: user.roleIds ? +user.roleIds.split(',')[0] : null,
            startDate: defaultDates.startDate,
            endDate: defaultDates.endDate,
            culture: this.locale == 'en' ? 'en-US' : this.locale,
            currentSalesMonth: config.currentSalesMonth,
            dayOfSalesMonth: config.dayOfSalesMonth,
            previousStartDate: defaultDates?.previousStartDate,
            previousEndDate: defaultDates?.previousEndDate,
            previousYearStartDate: defaultDates?.previousYearStartDate,
            previousYearEndDate: defaultDates?.previousYearEndDate,
            orgCode5: null,
            orgCode4: null,
            orgCode3: null,
            orgCode2: null,
            orgCode1: null,
            dealerCode: null,
            saleTypes: null,
            sourceTypeId: null,
            sourceTypes: config.sourceTypes.map(st => st.sourceTypeId).toString(),
            traits: config.traitFilters.length > 0 ? config.traitFilters.map(t => t.traitId).toString() : null,
            leadDesignations: Enums.leadDesignations.sentToDealer.leadDesignationId.toString(),
            leadTypeId: null,
            leadTypes: config.leadTypes.map(lt => lt.leadTypeId).toString(),
            vehicleMake: null,
            vehicleClass: null,
            vehicleModel: config.vehicleModels.map(m => m.vehicleModelId).toString(),
            vehicleModels: null,
            vehicleClasses: null,
            deviceTypeId: null,
            deviceTypes: null, //config.deviceTypes.map(dt => dt.deviceTypeId).toString(),
            orgLookupTypeId: 1,
            brands: null,
            campaignTypes: null,
            channels: null, //config.channels.map(c => c.channelId).toString(),
            profitCenterId: 1,
            digAdPrograms: config.digAdPrograms.map(p => p.digAdProgramId).toString(),
            reputationPlatforms: config.reputationPlatforms.map(c => c.reputationPlatformId).toString(),
            socialMediaPlatforms: config.socialMediaPlatforms.map(c => c.socialMediaPlatformId).toString(),
            chatProviders: null, //config.chatProviders.map(cp => cp.providerId).toString(),
            digAdProviders: null, //config.digAdProviders.map(dp => dp.providerId).toString(),
            socialMediaProviders: config.socialMediaProviders.map(sp => sp.providerId).toString(),
            reputationProviders: config.reputationProviders.map(rp => rp.providerId).toString(),
            websiteProviders: null,// config.websiteProviders.map(wp => wp.providerId).toString(),
            tradeInProviders: null, // config.tradeInProviders.map(tp => tp.providerId).toString(),
            serviceSchedulerProviders: config.serviceSchedulerProviders.map(ssp => ssp.providerId).toString(),
            appointmentDispositionActivityId: config.appointmentDispositionActivityId,
            showInactiveDealers: config.showInactiveDealerMetrics || true,
            dealerSize: null,
            vehicleTypes: null,
            dealTypes: null,
            leadGates: null,
            roles: null,
            pages: null,
            audienceSegments: null,
            journeySegments: null,
            salesUnits: null,//config.salesUnits.map(x => x.salesUnitId).toString(),
            productLines: null//config.productLines.map(x => x.productLineId).toString(),
          };
          // get vehicle tree
          // const vehicleOrgFilterTreeNodes: IFilterTreeNode[] = [];
          const vehicleOrgFilterTreeNodes = this.getVehicleLookupFilterNodes(config.vehicleLookups);

          // set org level defaults
          let orgLookup: Models.IOrgLookup;
          // Dealer
          if (this.configService.role.isDealerRole(user.role)) {
            orgLookup = config.orgLookups.filter(ol =>
              ol.dealerCode === user.roleEntity // we're looking at the right dealer
              && userOrgFilterTreeNodes[0].orgFilterValue.levelTypeId == ol.orgLookupTypeId // we're looking at the default orgLookupTypeId
            )[0];

            defaultFilter.orgLookupTypeId = orgLookup ? orgLookup.orgLookupTypeId : defaultFilter.orgLookupTypeId;
            defaultFilter.orgCode5 = orgLookup ? orgLookup.orgCode5 : null;
            defaultFilter.orgCode4 = orgLookup ? orgLookup.orgCode4 : null;
            defaultFilter.orgCode3 = orgLookup ? orgLookup.orgCode3 : null;
            defaultFilter.orgCode2 = orgLookup ? orgLookup.orgCode2 : null;
            defaultFilter.orgCode1 = orgLookup ? orgLookup.orgCode1 : null;
            defaultFilter.dealerCode = orgLookup ? orgLookup.dealerCode : null;
          }

          // District Manager
          if (this.configService.role.isOrg1Role(user.role)) {
            orgLookup = config.orgLookups.filter(ol => ol.orgCode1 === user.roleEntity // we're looking at the right org
              && userOrgFilterTreeNodes[0].orgFilterValue.levelTypeId == ol.orgLookupTypeId // we're looking at the default orgLookupTypeId
            )[0];

            defaultFilter.orgLookupTypeId = orgLookup ? orgLookup.orgLookupTypeId : defaultFilter.orgLookupTypeId;
            defaultFilter.orgCode5 = orgLookup ? orgLookup.orgCode5 : null;
            defaultFilter.orgCode4 = orgLookup ? orgLookup.orgCode4 : null;
            defaultFilter.orgCode3 = orgLookup ? orgLookup.orgCode3 : null;
            defaultFilter.orgCode2 = orgLookup ? orgLookup.orgCode2 : null;
            defaultFilter.orgCode1 = orgLookup ? orgLookup.orgCode1 : null;
          }

          // Area Manager
          if (this.configService.role.isOrg2Role(user.role)) {
            orgLookup = config.orgLookups.filter(ol => ol.orgCode2 === user.roleEntity // we're looking at the right org
              && userOrgFilterTreeNodes[0].orgFilterValue.levelTypeId == ol.orgLookupTypeId // we're looking at the default orgLookupTypeId
            )[0];

            defaultFilter.orgLookupTypeId = orgLookup ? orgLookup.orgLookupTypeId : defaultFilter.orgLookupTypeId;
            defaultFilter.orgCode5 = orgLookup ? orgLookup.orgCode5 : null;
            defaultFilter.orgCode4 = orgLookup ? orgLookup.orgCode4 : null;
            defaultFilter.orgCode3 = orgLookup ? orgLookup.orgCode3 : null;
            defaultFilter.orgCode2 = orgLookup ? orgLookup.orgCode2 : null;
          }

          // Region Manager
          if (this.configService.role.isOrg3Role(user.role)) {
            orgLookup = config.orgLookups.filter(ol => ol.orgCode3 === user.roleEntity // we're looking at the right org
              && userOrgFilterTreeNodes[0].orgFilterValue.levelTypeId == ol.orgLookupTypeId // we're looking at the default orgLookupTypeId
            )[0];

            defaultFilter.orgLookupTypeId = orgLookup ? orgLookup.orgLookupTypeId : defaultFilter.orgLookupTypeId;
            defaultFilter.orgCode5 = orgLookup ? orgLookup.orgCode5 : null;
            defaultFilter.orgCode4 = orgLookup ? orgLookup.orgCode4 : null;
            defaultFilter.orgCode3 = orgLookup ? orgLookup.orgCode3 : null;
          }

          if (this.configService.role.isOrg4Role(user.role)) {
            orgLookup = config.orgLookups.filter(ol => ol.orgCode4 === user.roleEntity // we're looking at the right org
              && userOrgFilterTreeNodes[0].orgFilterValue.levelTypeId == ol.orgLookupTypeId // we're looking at the default orgLookupTypeId
            )[0];

            defaultFilter.orgLookupTypeId = orgLookup ? orgLookup.orgLookupTypeId : defaultFilter.orgLookupTypeId;
            defaultFilter.orgCode5 = orgLookup ? orgLookup.orgCode5 : null;
            defaultFilter.orgCode4 = orgLookup ? orgLookup.orgCode4 : null;
          }

          if (this.configService.role.isOrg5Role(user.role)) {
            orgLookup = config.orgLookups.filter(ol => ol.orgCode5 === user.roleEntity // we're looking at the right org
              && userOrgFilterTreeNodes[0].orgFilterValue.levelTypeId == ol.orgLookupTypeId // we're looking at the default orgLookupTypeId
            )[0];

            defaultFilter.orgLookupTypeId = orgLookup ? orgLookup.orgLookupTypeId : defaultFilter.orgLookupTypeId;
            defaultFilter.orgCode5 = orgLookup ? orgLookup.orgCode5 : null;
          }

          defaultFilter.dayOfSalesMonth = config.dayOfSalesMonth;
          defaultFilter.currentSalesMonth = config.currentSalesMonth;

          return defaultFilter;
        }))));
  }

  getUpdatedFilterModel(reportName: string, filterType: string, selected: FilterModelTypes.FilterValueType[], filterModel: Models.IFilterModel, options: Partial<FilterOptions>) {
    const updatedFilterModel = { ...filterModel };

    // we cast to simple here because that's what most will be we re-cast for others below as neccessary
    const simpleFilterValue = <FilterModelTypes.SimpleFilterValue[]>selected;

    // determine optionals
    updatedFilterModel.includeMom = options.includeMOM;
    updatedFilterModel.includeYoy = options.includeYOY;

    // this is where we map the new filters concept to the old filter model
    switch (filterType) {
      case FilterModelTypes.FilterTypes.org:
        const orgFilterValue = <FilterModelTypes.OrgFilterValue>selected[0];

        updatedFilterModel.orgLookupTypeId = orgFilterValue.levelTypeId;

        switch (orgFilterValue.entityType) {
          case 'lma':
            updatedFilterModel.orgCode1 = orgFilterValue.value.toString();
            break;
          case 'virtual20':
            updatedFilterModel.orgCode1 = orgFilterValue.value.toString();
            break;
          case 'national':
            updatedFilterModel.orgCode5 = null;
            updatedFilterModel.orgCode4 = null;
            updatedFilterModel.orgCode3 = null;
            updatedFilterModel.orgCode2 = null;
            updatedFilterModel.orgCode1 = null;
            updatedFilterModel.dealerCode = null;
            break;
          case 'orgcode5':
            updatedFilterModel.orgCode5 = orgFilterValue.value.toString();
            updatedFilterModel.orgCode4 = null;
            updatedFilterModel.orgCode3 = null;
            updatedFilterModel.orgCode2 = null;
            updatedFilterModel.orgCode1 = null;
            updatedFilterModel.dealerCode = null;
            break;
          case 'orgcode4':
            updatedFilterModel.orgCode5 = null;
            updatedFilterModel.orgCode4 = orgFilterValue.value.toString();
            updatedFilterModel.orgCode3 = null;
            updatedFilterModel.orgCode2 = null;
            updatedFilterModel.orgCode1 = null;
            updatedFilterModel.dealerCode = null;
            break;
          case 'orgcode3':
            updatedFilterModel.orgCode5 = null;
            updatedFilterModel.orgCode4 = null;
            updatedFilterModel.orgCode3 = orgFilterValue.value.toString();
            updatedFilterModel.orgCode2 = null;
            updatedFilterModel.orgCode1 = null;
            updatedFilterModel.dealerCode = null;
            break;
          case 'orgcode2':
            updatedFilterModel.orgCode5 = null;
            updatedFilterModel.orgCode4 = null;
            updatedFilterModel.orgCode3 = null;
            updatedFilterModel.orgCode2 = orgFilterValue.value.toString();
            updatedFilterModel.orgCode1 = null;
            updatedFilterModel.dealerCode = null;
            break;
          case 'orgcode1':
            updatedFilterModel.orgCode5 = null;
            updatedFilterModel.orgCode4 = null;
            updatedFilterModel.orgCode3 = null;
            updatedFilterModel.orgCode2 = null;
            updatedFilterModel.orgCode1 = orgFilterValue.value.toString();
            updatedFilterModel.dealerCode = null;
            break;
          case 'dealer':
            updatedFilterModel.orgCode5 = null;
            updatedFilterModel.orgCode4 = null;
            updatedFilterModel.orgCode3 = null;
            updatedFilterModel.orgCode2 = null;
            updatedFilterModel.orgCode1 = null;
            updatedFilterModel.dealerCode = orgFilterValue.value.toString();
            break;
        }
        break;
      case FilterModelTypes.FilterTypes.vehicle:
        const vehicleFilterValue = <FilterModelTypes.OrgFilterValue>selected[0];
        switch (vehicleFilterValue.entityType) {
          case 'allclasses':
            updatedFilterModel.vehicleClass = null;
            updatedFilterModel.vehicleModel = null;
            break;
          case 'class':
            updatedFilterModel.vehicleClass = vehicleFilterValue.value.toString();
            updatedFilterModel.vehicleModel = null;
            break;
          case 'model':
            updatedFilterModel.vehicleClass = null;
            updatedFilterModel.vehicleModel = vehicleFilterValue.value.toString();
            break;
        }
        break;
      case FilterModelTypes.FilterTypes.date:
        const dateFilterValue = <FilterModelTypes.DateFilterValue>selected[0];
        updatedFilterModel.startDate = dateFilterValue.startDate;
        updatedFilterModel.endDate = dateFilterValue.endDate;
        updatedFilterModel.previousStartDate = dateFilterValue.previousStartDate;
        updatedFilterModel.previousEndDate = dateFilterValue.previousEndDate;
        updatedFilterModel.previousYearStartDate = dateFilterValue.previousYearStartDate;
        updatedFilterModel.previousYearEndDate = dateFilterValue.previousYearEndDate;
        updatedFilterModel.orgDateCode = dateFilterValue.orgDateCode;
        break;
      // TODO - Everything below this is bloat and debt. we need a more functional and flexible way to do this
      case FilterModelTypes.FilterTypes.brand:
        updatedFilterModel.brands = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.campaignType:
        updatedFilterModel.campaignTypes = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.audienceSegment:
        updatedFilterModel.audienceSegments = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.journeySegment:
        updatedFilterModel.journeySegments = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.dealerSize:
        updatedFilterModel.dealerSize = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.channel:
      case FilterModelTypes.FilterTypes.digAdChannel:
        updatedFilterModel.channels = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.chatProvider:
        updatedFilterModel.chatProviders = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.deviceType:
        updatedFilterModel.deviceTypes = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.digAdProvider:
        updatedFilterModel.digAdProviders = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.digitalRetailingProvider:
        updatedFilterModel.providers = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.inventoryStatus:
        updatedFilterModel.inventoryStatus = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.inventoryTypes:
        updatedFilterModel.inventoryTypes = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.leadType:
        updatedFilterModel.leadTypes = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.lma:
        updatedFilterModel.orgCode1 = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.lmaRegion:
        updatedFilterModel.orgCode2 = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.profitCenter:
        updatedFilterModel.profitCenterId = <number>simpleFilterValue[0].value;
        updatedFilterModel.profitCenters = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.digAdProgram:
        updatedFilterModel.digAdPrograms = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.reputationPlatform:
        updatedFilterModel.reputationPlatforms = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.reputationProvider:
        updatedFilterModel.reputationProviders = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.saleType:
        updatedFilterModel.saleTypes = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.socialMediaPlatform:
        updatedFilterModel.socialMediaPlatforms = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.socialMediaProvider:
        updatedFilterModel.socialMediaProviders = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.sourceType:
        updatedFilterModel.sourceTypes = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.strategy:
        updatedFilterModel.strategies = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.tactic:
        updatedFilterModel.tactics = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.trait:
        updatedFilterModel.traits = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.vehicleMakes:
        updatedFilterModel.vehicleMakes = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.vehicleMake:
        updatedFilterModel.vehicleMakes = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.vehicleClass:
        updatedFilterModel.vehicleClasses = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.vehicleModel:
        updatedFilterModel.vehicleModels = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.vehicleModels:
        updatedFilterModel.vehicleModels = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.vehicleSeries:
        updatedFilterModel.vehicleSeries = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.websiteProvider:
        updatedFilterModel.websiteProviders = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.tradeInProvider:
        updatedFilterModel.tradeInProviders = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.serviceSchedulerProvider:
        updatedFilterModel.serviceSchedulerProviders = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.vehicleType:
        updatedFilterModel.vehicleTypes = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.dealType:
        updatedFilterModel.dealTypes = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.leadGate:
        updatedFilterModel.leadGates = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.role:
        updatedFilterModel.roles = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.page:
        updatedFilterModel.pages = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.salesUnit:
        updatedFilterModel.salesUnits = simpleFilterValue.map(s => s.value).toString();
        break;
      case FilterModelTypes.FilterTypes.productLine:
        updatedFilterModel.productLines = simpleFilterValue.map(s => s.value).toString();
        break;
    }

    return updatedFilterModel;
  }

  getFilterModelFromFilters(reportName: string, defaultFilterModel: IFilterModel, filters: FilterModelTypes.Filter[], options: Partial<FilterOptions>) {
    let newFilterModel = { ...defaultFilterModel };
    filters.forEach(f => {
      newFilterModel = this.getUpdatedFilterModel(reportName, f.type, f.selected, newFilterModel, options);
    });
    return newFilterModel;
  }


  updateFilterSelected(reportName: string, filterType: string, selected: FilterModelTypes.FilterValueType[]) {
    this.store$.dispatch(FilterActions.updateCurrentReportFilterSelected({ reportName, filterType, selected }));
  }

  resetFilters() {
    this.filterLoadRequested.clear();
    this.store$.dispatch(FilterActions.resetFilters());
  }

  getDefaultFilterConfigs() {
    return this.store$.select(AppSelectors.selectLoggedInUserAndConfig).pipe(
      filter(
        ([user, config]) =>
          !!user && user.isAuthenticated && config.orgLookups.length > 0
      ),
      tap(([user, config]) => {
        this.user = user;
        this.config = config;
      }),
      withLatestFrom(this.filterDateService.getDefaultDateFilter()),
      map(([[user, config], defaultDateFilter]) => {
        // TODO: Make this a function on the filter config service
        const filterKeys = Object.keys(this.configService.filter.filterConfig.FILTER_CONFIG);
        const filters: FilterModelTypes.Filter[] = [];

        // push a default date filter
        filters.push({
          ...defaultDateFilter,
          locked: true
        });

        filterKeys.forEach(filterKey => {
          // TODO: Make this a funciton on the filter config service
          const defaultFilter = { ...this.configService.filter.filterConfig.FILTER_CONFIG[filterKey] };

          // handle filter specific initialization
          switch (filterKey) {
            case 'org':
              defaultFilter.selected = [this.getDefaultOrgFilterValue()];
              break;
            case 'vehicle':
              defaultFilter.selected = [this.getDefaultVehicleLookupFilterValue()];
              break;
          }

          filters.push(defaultFilter);
        });

        return filters;
      })
    );
  }

  getReportCompatibleOrgFilter(reportName: string, orgFilter: FilterModelTypes.Filter): FilterModelTypes.Filter {
    return {
      ...orgFilter,
      selected: [this.getReportCompatibleOrgLevelFilterValue(reportName, <FilterModelTypes.OrgFilterValue>orgFilter.selected[0])]
    };
  }

  getReportCompatibleOrgLevelFilterValue(
    reportName: string,
    filterValue: FilterModelTypes.OrgFilterValue
  ) {
    const reportConfig = this.configService.filter.filterConfig.filterReportConfigs[reportName];
    const orgTypes = reportConfig.orgTypes || [
      ...environment.defaultOrgLevelTypeIds
    ];

    if (orgTypes.indexOf(filterValue?.levelTypeId) >= 0) {
      return filterValue;
    } else {
      return this.getDefaultOrgFilterValue();
    }
  }

  getReportCompatibleDateFilter(reportName: string, dateFilter: FilterModelTypes.Filter, dateRanges: DateRangeSet): FilterModelTypes.Filter {
    return {
      ...dateFilter,
      selected: [this.getReportCompatibleDateFilterValue(reportName, <FilterModelTypes.DateFilterValue>dateFilter.selected[0], dateRanges)]
    };
  }

  getReportCompatibleDateFilterValue(
    reportName: string,
    filterValue: FilterModelTypes.DateFilterValue,
    dateRanges: DateRangeSet
  ) {
    const reportConfig = this.configService.filter.filterConfig.filterReportConfigs[reportName];

    // if a report is configured to use restricted dates, this typically means single month selection
    // if not, it can use any date range currently
    if (!reportConfig.restrictDates) {
      return filterValue;
    }
    else if (!!reportConfig.quarterDateModeEnabled && filterValue.dateModeId === Enums.dateModes.quarterly.dateModeId) {
      // enumerate our quarter items and see if one exists with the same start and end date, if it does, the date filter value is good
      // if it doesn't, we'll use the end date to determine which one to use

      let foundQuarterItem = dateRanges.quarters.find(dr => dr.startDate === filterValue.startDate && dr.endDate === filterValue.endDate);

      if (foundQuarterItem) {
        return filterValue;
      }

      foundQuarterItem = dateRanges.quarters.find(dr => moment(filterValue.endDate) >= moment(dr.startDate)
        && moment(filterValue.endDate) <= moment(dr.endDate));

      return foundQuarterItem;
    }
    else {
      // enumerate our month items and see if one exists with the same start and end date, if it does, the date filter value is good
      // if it doesn't, we'll use the end date to determine which one to use
      let foundMonthItem = dateRanges.months.find(dr => dr.startDate === filterValue.startDate && dr.endDate === filterValue.endDate);

      if (foundMonthItem) {
        return filterValue;
      }

      foundMonthItem = dateRanges.months.find(dr => moment(filterValue.endDate) >= moment(dr.startDate)
        && moment(filterValue.endDate) <= moment(dr.endDate));

      return foundMonthItem;
    }
  }

  getOrgLookupFilterNodes(): Observable<FilterPanelTypes.IFilterTreeNode[]> {
    return this.store$.select(AppSelectors.selectLoggedInUserAndConfig)
      .pipe(
        filter(([user]) => !!user),
        map(([user, config]) => {

          // TODO: Clean this up...it is hard to read
          const models: FilterPanelTypes.IFilterTreeNode[] = [];
          let userOrgLookupTypeIds: number[] = null;

          if (!!user.orgLookupTypeIds && user.orgLookupTypeIds != '') {
            const ids = [];
            user.orgLookupTypeIds.split(",").forEach(id => { ids.push(parseInt(id, 10)) });
            userOrgLookupTypeIds = [...ids];
          }

          const orgLookups = config.orgLookups.filter(ol => {
            // Get the available 'National' OrgLookup records for the user
            return (!!userOrgLookupTypeIds ? userOrgLookupTypeIds.includes(ol.orgLookupTypeId) : true)
              && this.configService.org.IsNationalOrgLookupType(ol.orgLookupTypeId);
          });

          if (!!this.configService.role.isDealerGroupRole(user.role)) {
            models.push.apply(models, this.getDealerGroupFilterNodes(config.dealerLookups, user.roleEntity));
          }
          else {

            let nationalOlts: IOrgLookupType[] = [];

            if ((userOrgLookupTypeIds || []).length === 0) {
              nationalOlts = [...this.configService.org.nationalOrgLookups]
            }
            else {
              this.configService.org.nationalOrgLookups.forEach(olt => {
                if (userOrgLookupTypeIds.indexOf(olt.orgLookupTypeId) != -1) {
                  nationalOlts.push(olt);
                }
              });
            }

            nationalOlts.forEach(nolt => {
              let filteredOrgLookups = orgLookups
                .filter(ol => ol.orgLookupTypeId === nolt.orgLookupTypeId)
                .filter(ol => (this.configService.defaultAppSettings.showInactiveDealers || ol.dealerIsActive));
              let orgHierarchyDepth = this.getOrgHierarchyDepth(filteredOrgLookups);
              let userOrgHierarchyDepthAccess = this.getUserOrgHierarchyDepthAccess(user.role);

              models.push.apply(models, this.getNationalOrgFilterNodes(
                filteredOrgLookups,
                nolt,
                orgHierarchyDepth,
                userOrgHierarchyDepthAccess,
                user.roleEntity
              ));
            });

            // Handle LMA & Virtual20
            if (this.configService.role.isSysAdminRole(user.role) || this.configService.role.isCorporateRole(user.role)) {
              if (!!(this.configService.org.orgConfig.orgLookupTypes['lma'])) {
                models.push.apply(models, this.getLMAFilterNodes(config.lmaLookups));
              }
              if (!!(this.configService.org.orgConfig.orgLookupTypes['virtual20'])) {
                models.push.apply(models, this.getVirtual20FilterNodes(config.virtual20Lookups));
              }
            }
          }

          return models;
        })
      );
  }

  getLMAFilterNodes(lmaLookups: Models.IOrgLookup[]): FilterPanelTypes.IFilterTreeNode[] {
    const lmaFormatDisplay = (node: FilterPanelTypes.IFilterTreeNode) => node.display;

    const orgs: IOrgLookupType[] = [];
    Object.keys(this.configService.org.orgConfig.orgLookupTypes).forEach(olt => orgs.push(this.configService.org.orgConfig.orgLookupTypes[olt]));
    const lmaInfo = orgs.find(o => this.configService.org.IsLmaOrgLookupType(o.orgLookupTypeId));

    return [{
      display: lmaInfo.name,
      value: 'LMA',
      unselectable: true,
      orgFilterValue: { display: lmaInfo.name, value: 'LMA', entityType: 'lma', levelTypeId: lmaInfo.orgLookupTypeId },
      formatDisplay: lmaFormatDisplay,
      children: lmaLookups.filter(ol => this.configService.org.IsLmaOrgLookupType(ol.orgLookupTypeId))
        .map(ol => (<FilterPanelTypes.IFilterTreeNode>{
          display: ol.orgCode1Name,
          value: ol.orgCode1,
          orgFilterValue: {
            display: ol.orgCode1Name,
            value: ol.orgCode1,
            entityType: 'lma',
            levelTypeId: ol.orgLookupTypeId
          },
          formatDisplay: lmaFormatDisplay
        }))
    }];
  }

  getVirtual20FilterNodes(virtual20Lookups: Models.IOrgLookup[]): FilterPanelTypes.IFilterTreeNode[] {
    const virtual20FormatDisplay = (node: FilterPanelTypes.IFilterTreeNode) => node.display;

    const orgs: IOrgLookupType[] = [];
    Object.keys(this.configService.org.orgConfig.orgLookupTypes).forEach(olt => orgs.push(this.configService.org.orgConfig.orgLookupTypes[olt]));
    const virtual20Info = orgs.find(o => this.configService.org.IsVirtual20OrgLookupType(o.orgLookupTypeId));

    return [{
      display: virtual20Info.name,
      value: 'Virtual 20',
      unselectable: true,
      orgFilterValue: { display: virtual20Info.name, value: 'Virtual 20', entityType: 'virtual20', levelTypeId: virtual20Info.orgLookupTypeId },
      formatDisplay: virtual20FormatDisplay,
      children: virtual20Lookups.filter(ol => this.configService.org.IsVirtual20OrgLookupType(ol.orgLookupTypeId))
        .map(ol => (<FilterPanelTypes.IFilterTreeNode>{
          display: ol.orgCode1Name,
          value: ol.orgCode1,
          orgFilterValue: {
            display: ol.orgCode1Name,
            value: ol.orgCode1,
            entityType: 'virtual20',
            levelTypeId: ol.orgLookupTypeId
          },
          formatDisplay: virtual20FormatDisplay
        }))
    }];
  }

  getDealerGroupFilterNodes(dealerLookups: Models.IDealerLookup[], dealerGroup: string): FilterPanelTypes.IFilterTreeNode[] {
    const filtered = dealerLookups.filter(dl => this.configService.org.IsDealerGroupOrgLookupType(dl.orgLookupTypeId) && dl.orgCode1.toLocaleLowerCase() === dealerGroup.toLocaleLowerCase());

    if (!filtered?.length) {
      return null;
    }

    const first = filtered[0];

    // we build up a composite value based on org type and value so we don't collide w/ other geos with similar values

    let nodes: FilterPanelTypes.IFilterTreeNode[] = [
      {
        unselectable: false,
        display: first.orgCode1Name,
        value: `${first.orgLookupTypeId}-${first.orgCode1}`,
        orgFilterValue: { display: first.orgCode1Name, value: first.orgCode1, entityType: 'orgcode1', levelTypeId: first.orgLookupTypeId },
        children: filtered.map(d => {
          return <FilterPanelTypes.IFilterTreeNode>{
            display: d.dealerName,
            value: `${first.orgLookupTypeId}-${d.dealerCode}`,
            orgFilterValue: { display: d.dealerName, value: d.dealerCode, entityType: 'dealer', levelTypeId: first.orgLookupTypeId }
          }
        })
      }
    ];

    return nodes;
  }

  getNationalOrgFilterNodes(orgLookups: Models.IOrgLookup[], orgLookupType: IOrgLookupType, orgHierarchyDepth: number, userOrgHierarchyDepthAccess: number, roleEntity: string): FilterPanelTypes.IFilterTreeNode[] {
    let nodes: FilterPanelTypes.IFilterTreeNode[] = [];
    let validOrgLookupTypeForUser: boolean = this.validateOrgLookupTypeForUser(orgLookups, userOrgHierarchyDepthAccess, roleEntity);

    if (!validOrgLookupTypeForUser) {
      return [];
    }

    if (this.configService.filter.filterConfig.showEntireOrgHierarchyForAllUsers) {
      nodes.push({
        display: orgLookupType.name,
        unselectable: userOrgHierarchyDepthAccess <= orgHierarchyDepth,
        value: `${orgLookupType.orgLookupTypeId}-${orgLookupType.name}`,
        orgFilterValue: { display: orgLookupType.name, value: orgLookupType.name, entityType: 'national', levelTypeId: orgLookupType.orgLookupTypeId },
        children: this.getNationalOrgFilterChildNodes(orgLookups, orgLookupType, orgHierarchyDepth, userOrgHierarchyDepthAccess, roleEntity)
      });
    }
    else {
      if (userOrgHierarchyDepthAccess === constants.orgHierarchyDepth.national) {
        nodes.push({
          display: orgLookupType.name,
          unselectable: userOrgHierarchyDepthAccess <= orgHierarchyDepth,
          value: `${orgLookupType.orgLookupTypeId}-${orgLookupType.name}`,
          orgFilterValue: { display: orgLookupType.name, value: orgLookupType.name, entityType: 'national', levelTypeId: orgLookupType.orgLookupTypeId },
          children: this.getNationalOrgFilterChildNodes(orgLookups, orgLookupType, orgHierarchyDepth, userOrgHierarchyDepthAccess, roleEntity)
        });
      }
      else {
        let highestOrgHierarchyDepth = userOrgHierarchyDepthAccess;
        nodes = [...this.getNationalOrgFilterChildNodes(orgLookups, orgLookupType, highestOrgHierarchyDepth, userOrgHierarchyDepthAccess, roleEntity)];
      }
    }

    return nodes;
  }

  getNationalOrgFilterChildNodes(orgLookups: Models.IOrgLookup[], orgLookupType: IOrgLookupType, currentHierarchyDepth: number, userOrgHierarchyDepthAccess: number, roleEntity: string): FilterPanelTypes.IFilterTreeNode[] {
    let nodes: FilterPanelTypes.IFilterTreeNode[] = [];
    let currentOrgLevelOrgCodes = [...this.getUserOrgCodesForHierarchyLevel(orgLookups, currentHierarchyDepth, userOrgHierarchyDepthAccess, roleEntity)]

    if (currentHierarchyDepth === constants.orgHierarchyDepth.org5) {
      currentOrgLevelOrgCodes.forEach(orgCode => {
        let orgLookup = orgLookups.find(ol => ol.orgCode5 === orgCode);
        let childOrgLookups = orgLookups.filter(ol => ol.orgCode5 === orgCode);
        nodes.push({
          unselectable: userOrgHierarchyDepthAccess < currentHierarchyDepth,
          display: orgLookup.orgCode5FilterDisplayName || orgLookup.orgCode5Name,
          value: `${orgLookup.orgLookupTypeId}-${orgLookup.orgCode5}`,
          orgFilterValue: { display: orgLookup.orgCode5Name, value: orgLookup.orgCode5, entityType: 'orgcode5', levelTypeId: orgLookup.orgLookupTypeId },
          children: this.getNationalOrgFilterChildNodes(childOrgLookups, orgLookupType, currentHierarchyDepth - 1, userOrgHierarchyDepthAccess, roleEntity)
        });
      })
    }
    else if (currentHierarchyDepth === constants.orgHierarchyDepth.org4) {
      currentOrgLevelOrgCodes.forEach(orgCode => {
        let orgLookup = orgLookups.find(ol => ol.orgCode4 === orgCode);
        let childOrgLookups = orgLookups.filter(ol => ol.orgCode4 === orgCode);
        nodes.push({
          unselectable: userOrgHierarchyDepthAccess < currentHierarchyDepth,
          display: orgLookup.orgCode4FilterDisplayName || orgLookup.orgCode4Name,
          value: `${orgLookup.orgLookupTypeId}-${orgLookup.orgCode4}`,
          orgFilterValue: { display: orgLookup.orgCode4Name, value: orgLookup.orgCode4, entityType: 'orgcode4', levelTypeId: orgLookup.orgLookupTypeId },
          children: this.getNationalOrgFilterChildNodes(childOrgLookups, orgLookupType, currentHierarchyDepth - 1, userOrgHierarchyDepthAccess, roleEntity)
        });
      })
    }
    else if (currentHierarchyDepth === constants.orgHierarchyDepth.org3) {
      currentOrgLevelOrgCodes.forEach(orgCode => {
        let orgLookup = orgLookups.find(ol => ol.orgCode3 === orgCode);
        let childOrgLookups = orgLookups.filter(ol => ol.orgCode3 === orgCode);
        nodes.push({
          unselectable: userOrgHierarchyDepthAccess < currentHierarchyDepth,
          display: orgLookup.orgCode3FilterDisplayName || orgLookup.orgCode3Name,
          value: `${orgLookup.orgLookupTypeId}-${orgLookup.orgCode3}`,
          orgFilterValue: { display: orgLookup.orgCode3Name, value: orgLookup.orgCode3, entityType: 'orgcode3', levelTypeId: orgLookup.orgLookupTypeId },
          children: this.getNationalOrgFilterChildNodes(childOrgLookups, orgLookupType, currentHierarchyDepth - 1, userOrgHierarchyDepthAccess, roleEntity)
        });
      })
    }
    else if (currentHierarchyDepth === constants.orgHierarchyDepth.org2) {
      currentOrgLevelOrgCodes.forEach(orgCode => {
        let orgLookup = orgLookups.find(ol => ol.orgCode2 === orgCode);
        let childOrgLookups = orgLookups.filter(ol => ol.orgCode2 === orgCode);
        nodes.push({
          unselectable: userOrgHierarchyDepthAccess < currentHierarchyDepth,
          display: orgLookup.orgCode2FilterDisplayName || orgLookup.orgCode2Name,
          value: `${orgLookup.orgLookupTypeId}-${orgLookup.orgCode2}`,
          orgFilterValue: { display: orgLookup.orgCode2Name, value: orgLookup.orgCode2, entityType: 'orgcode2', levelTypeId: orgLookup.orgLookupTypeId },
          children: this.getNationalOrgFilterChildNodes(childOrgLookups, orgLookupType, currentHierarchyDepth - 1, userOrgHierarchyDepthAccess, roleEntity)
        });
      })
    }
    else if (currentHierarchyDepth === constants.orgHierarchyDepth.org1) {
      currentOrgLevelOrgCodes.forEach(orgCode => {
        let orgLookup = orgLookups.find(ol => ol.orgCode1 === orgCode);
        let childOrgLookups = orgLookups.filter(ol => ol.orgCode1 === orgCode);
        nodes.push({
          unselectable: userOrgHierarchyDepthAccess < currentHierarchyDepth,
          display: orgLookup.orgCode1FilterDisplayName || orgLookup.orgCode1Name,
          value: `${orgLookup.orgLookupTypeId}-${orgLookup.orgCode1}`,
          orgFilterValue: { display: orgLookup.orgCode1Name, value: orgLookup.orgCode1, entityType: 'orgcode1', levelTypeId: orgLookup.orgLookupTypeId },
          children: this.getNationalOrgFilterChildNodes(childOrgLookups, orgLookupType, currentHierarchyDepth - 1, userOrgHierarchyDepthAccess, roleEntity)
        });
      })
    }
    else if (currentHierarchyDepth === constants.orgHierarchyDepth.dealer) {
      // Dealer level
      currentOrgLevelOrgCodes.forEach(dealerCode => {
        let orgLookup = orgLookups.find(ol => ol.dealerCode === dealerCode);
        nodes.push({
          unselectable: userOrgHierarchyDepthAccess < currentHierarchyDepth,
          display: orgLookup.dealerFilterDisplayName || orgLookup.dealerName,
          value: `${orgLookup.orgLookupTypeId}-${orgLookup.dealerCode}`,
          orgFilterValue: { display: orgLookup.dealerName, value: orgLookup.dealerCode, entityType: 'dealer', levelTypeId: orgLookup.orgLookupTypeId },
          children: []
        });
      })
    }

    return nodes;
  }

  getUserOrgCodesForHierarchyLevel(orgLookups: Models.IOrgLookup[], currentHierarchyDepth: number, userOrgHierarchyDepthAccess: number, roleEntity: string) {
    // This assumes that the orgLookups passed in are for a single orgLookupTypeId - pre-filtered

    if (userOrgHierarchyDepthAccess === constants.orgHierarchyDepth.national) {
      // User has access to entire hierarchy
      if (currentHierarchyDepth === constants.orgHierarchyDepth.org5) {
        // Get distinct orgCode5 values
        return orgLookups.map(ol => ol.orgCode5).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org4) {
        // Get distinct orgCode4 values
        return orgLookups.map(ol => ol.orgCode4).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org3) {
        // Get distinct orgCode3 values
        return orgLookups.map(ol => ol.orgCode3).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org2) {
        // Get distinct orgCode2 values
        return orgLookups.map(ol => ol.orgCode2).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org1) {
        // Get distinct orgCode1 values
        return orgLookups.map(ol => ol.orgCode1).filter((value, index, self) => self.indexOf(value) === index)
      }
      else {
        // Get distinct dealerCode values
        return orgLookups.map(ol => ol.dealerCode).filter((value, index, self) => self.indexOf(value) === index);
      }
    }
    else if (userOrgHierarchyDepthAccess === constants.orgHierarchyDepth.org5) {
      // Org5 user - assume that the hierarchy has 5 levels
      if (currentHierarchyDepth === constants.orgHierarchyDepth.org5) {
        return orgLookups.filter(ol => ol.orgCode5 === roleEntity).map(ol => ol.orgCode5).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org4) {
        return orgLookups.filter(ol => ol.orgCode5 === roleEntity).map(ol => ol.orgCode4).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org3) {
        return orgLookups.filter(ol => ol.orgCode5 === roleEntity).map(ol => ol.orgCode3).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org2) {
        return orgLookups.filter(ol => ol.orgCode5 === roleEntity).map(ol => ol.orgCode2).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org1) {
        return orgLookups.filter(ol => ol.orgCode5 === roleEntity).map(ol => ol.orgCode1).filter((value, index, self) => self.indexOf(value) === index)
      }
      else {
        return orgLookups.filter(ol => ol.orgCode5 === roleEntity).map(ol => ol.dealerCode).filter((value, index, self) => self.indexOf(value) === index)
      }
    }
    else if (userOrgHierarchyDepthAccess === constants.orgHierarchyDepth.org4) {
      // Org4 user - assume that the hierarchy has 4 levels
      if (currentHierarchyDepth === constants.orgHierarchyDepth.org5) {
        return orgLookups.filter(ol => ol.orgCode4 === roleEntity).map(ol => ol.orgCode5).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org4) {
        return orgLookups.filter(ol => ol.orgCode4 === roleEntity).map(ol => ol.orgCode4).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org3) {
        return orgLookups.filter(ol => ol.orgCode4 === roleEntity).map(ol => ol.orgCode3).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org2) {
        return orgLookups.filter(ol => ol.orgCode4 === roleEntity).map(ol => ol.orgCode2).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org1) {
        return orgLookups.filter(ol => ol.orgCode4 === roleEntity).map(ol => ol.orgCode1).filter((value, index, self) => self.indexOf(value) === index)
      }
      else {
        return orgLookups.filter(ol => ol.orgCode4 === roleEntity).map(ol => ol.dealerCode).filter((value, index, self) => self.indexOf(value) === index)
      }
    }
    else if (userOrgHierarchyDepthAccess === constants.orgHierarchyDepth.org3) {
      // Org3 user - assume that the hierarchy has 3 levels
      if (currentHierarchyDepth === constants.orgHierarchyDepth.org5) {
        return orgLookups.filter(ol => ol.orgCode3 === roleEntity).map(ol => ol.orgCode5).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org4) {
        return orgLookups.filter(ol => ol.orgCode3 === roleEntity).map(ol => ol.orgCode4).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org3) {
        return orgLookups.filter(ol => ol.orgCode3 === roleEntity).map(ol => ol.orgCode3).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org2) {
        return orgLookups.filter(ol => ol.orgCode3 === roleEntity).map(ol => ol.orgCode2).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org1) {
        return orgLookups.filter(ol => ol.orgCode3 === roleEntity).map(ol => ol.orgCode1).filter((value, index, self) => self.indexOf(value) === index)
      }
      else {
        return orgLookups.filter(ol => ol.orgCode3 === roleEntity).map(ol => ol.dealerCode).filter((value, index, self) => self.indexOf(value) === index)
      }
    }
    else if (userOrgHierarchyDepthAccess === constants.orgHierarchyDepth.org2) {
      // Org2 user
      if (currentHierarchyDepth === constants.orgHierarchyDepth.org5) {
        return orgLookups.filter(ol => ol.orgCode2 === roleEntity).map(ol => ol.orgCode5).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org4) {
        return orgLookups.filter(ol => ol.orgCode2 === roleEntity).map(ol => ol.orgCode4).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org3) {
        return orgLookups.filter(ol => ol.orgCode2 === roleEntity).map(ol => ol.orgCode3).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org2) {
        return orgLookups.filter(ol => ol.orgCode2 === roleEntity).map(ol => ol.orgCode2).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org1) {
        return orgLookups.filter(ol => ol.orgCode2 === roleEntity).map(ol => ol.orgCode1).filter((value, index, self) => self.indexOf(value) === index)
      }
      else {
        return orgLookups.filter(ol => ol.orgCode2 === roleEntity).map(ol => ol.dealerCode).filter((value, index, self) => self.indexOf(value) === index)
      }
    }
    else if (userOrgHierarchyDepthAccess === constants.orgHierarchyDepth.org1) {
      // Org1 user
      if (currentHierarchyDepth === constants.orgHierarchyDepth.org5) {
        return orgLookups.filter(ol => ol.orgCode1 === roleEntity).map(ol => ol.orgCode5).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org4) {
        return orgLookups.filter(ol => ol.orgCode1 === roleEntity).map(ol => ol.orgCode4).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org3) {
        return orgLookups.filter(ol => ol.orgCode1 === roleEntity).map(ol => ol.orgCode3).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org2) {
        return orgLookups.filter(ol => ol.orgCode1 === roleEntity).map(ol => ol.orgCode2).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org1) {
        return orgLookups.filter(ol => ol.orgCode1 === roleEntity).map(ol => ol.orgCode1).filter((value, index, self) => self.indexOf(value) === index)
      }
      else {
        return orgLookups.filter(ol => ol.orgCode1 === roleEntity).map(ol => ol.dealerCode).filter((value, index, self) => self.indexOf(value) === index)
      }
    }
    else {
      // Dealer user
      if (currentHierarchyDepth === constants.orgHierarchyDepth.org5) {
        return orgLookups.filter(ol => ol.dealerCode === roleEntity).map(ol => ol.orgCode5).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org4) {
        return orgLookups.filter(ol => ol.dealerCode === roleEntity).map(ol => ol.orgCode4).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org3) {
        return orgLookups.filter(ol => ol.dealerCode === roleEntity).map(ol => ol.orgCode3).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org2) {
        return orgLookups.filter(ol => ol.dealerCode === roleEntity).map(ol => ol.orgCode2).filter((value, index, self) => self.indexOf(value) === index)
      }
      else if (currentHierarchyDepth === constants.orgHierarchyDepth.org1) {
        return orgLookups.filter(ol => ol.dealerCode === roleEntity).map(ol => ol.orgCode1).filter((value, index, self) => self.indexOf(value) === index)
      }
      else {
        return orgLookups.filter(ol => ol.dealerCode === roleEntity).map(ol => ol.dealerCode).filter((value, index, self) => self.indexOf(value) === index)
      }
    }

  }

  getOrgHierarchyDepth(orgLookups: Models.IOrgLookup[]) {
    if (this.configService.filter.filterConfig.onlyShowDealersInFilter) {
      return constants.orgHierarchyDepth.dealer;
    }
    else if (!!(orgLookups.find(ol => !!ol.orgCode5))) {
      return constants.orgHierarchyDepth.org5;
    }
    else if (!!(orgLookups.find(ol => !!ol.orgCode4))) {
      return constants.orgHierarchyDepth.org4;
    }
    else if (!!(orgLookups.find(ol => !!ol.orgCode3))) {
      return constants.orgHierarchyDepth.org3;
    }
    else if (!!(orgLookups.find(ol => !!ol.orgCode2))) {
      return constants.orgHierarchyDepth.org2;
    }
    else if (!!(orgLookups.find(ol => !!ol.orgCode1))) {
      return constants.orgHierarchyDepth.org1;
    }
    else {
      return constants.orgHierarchyDepth.dealer;
    }
  }

  getUserOrgHierarchyDepthAccess(role: string) {
    if (this.configService.role.isSysAdminRole(role) || this.configService.role.isCorporateRole(role)) {
      // If we have 3 org levels, we want national/corp users to be able to view everything above hierarchy depth 3 (OrgCode3 and up)
      return constants.orgHierarchyDepth.national;
    }
    if (this.configService.role.isOrg5Role(role)) {
      return constants.orgHierarchyDepth.org5;
    }
    if (this.configService.role.isOrg4Role(role)) {
      return constants.orgHierarchyDepth.org4;
    }
    if (this.configService.role.isOrg3Role(role)) {
      return constants.orgHierarchyDepth.org3;
    }
    if (this.configService.role.isOrg2Role(role)) {
      return constants.orgHierarchyDepth.org2;
    }
    if (this.configService.role.isOrg1Role(role)) {
      return constants.orgHierarchyDepth.org1;
    }
    if (this.configService.role.isDealerRole(role)) {
      return constants.orgHierarchyDepth.dealer;
    }
  }

  validateOrgLookupTypeForUser(orgLookups: Models.IOrgLookup[], userOrgHierarchyDepthAccess: number, roleEntity: string) {
    switch (userOrgHierarchyDepthAccess) {
      case constants.orgHierarchyDepth.national:
        return true;
      case constants.orgHierarchyDepth.org5:
        return orgLookups.findIndex(ol => ol.orgCode5 === roleEntity) != -1
      case constants.orgHierarchyDepth.org4:
        return orgLookups.findIndex(ol => ol.orgCode4 === roleEntity) != -1
      case constants.orgHierarchyDepth.org3:
        return orgLookups.findIndex(ol => ol.orgCode3 === roleEntity) != -1
      case constants.orgHierarchyDepth.org2:
        return orgLookups.findIndex(ol => ol.orgCode2 === roleEntity) != -1
      case constants.orgHierarchyDepth.org1:
        return orgLookups.findIndex(ol => ol.orgCode1 === roleEntity) != -1
      case constants.orgHierarchyDepth.dealer:
        return orgLookups.findIndex(ol => ol.dealerCode === roleEntity) != -1
      default:
        return false;
    }
  }

  getVehicleLookupFilterNodes(vehicleLookups: Models.IVehicleLookup[]): FilterPanelTypes.IFilterTreeNode[] {
    const models: FilterPanelTypes.IFilterTreeNode[] = [];
    models.push(this.getAllVehicleClassLookupFilterNodes(vehicleLookups));

    return models;
  }

  getAllVehicleClassLookupFilterNodes(vehicleLookups: Models.IVehicleLookup[]): FilterPanelTypes.IFilterTreeNode {
    return {
      display: 'All Classes',
      value: 'All Classes',
      orgFilterValue: { display: 'All Classes', value: 'All Classes', entityType: 'allclasses', levelTypeId: 1 },
      children: this.getVehicleClassFilterNodes(vehicleLookups)
    };
  }

  getVehicleClassFilterNodes(vehicleLookups: Models.IVehicleLookup[], vehicleCass?: string): FilterPanelTypes.IFilterTreeNode[] {
    return vehicleLookups
      .filter(v => !vehicleCass || v.vehicleClass === vehicleCass)
      .filter((vl, idx, self) => self.map(s => s.vehicleClass).indexOf(vl.vehicleClass) === idx)
      .map(vl => {
        return <FilterPanelTypes.IFilterTreeNode>{
          display: vl.vehicleClass,
          value: vl.vehicleClass,
          orgFilterValue: { display: vl.vehicleClass, value: vl.vehicleClass, entityType: 'class', levelTypeId: 1 },
          children: this.getVehicleModelFilterNodes(vehicleLookups, vl.vehicleClass)
        };
      });
  }

  getVehicleModelFilterNodes(vehicleLookups: Models.IVehicleLookup[], vehicleClass?: string, vehicleModel?: string) {
    return vehicleLookups
      .filter(vl => vl.vehicleClass === vehicleClass && (!vehicleModel || vl.vehicleModel === vehicleModel))
      .filter((vl, idx, self) => self.map(s => s.vehicleModel).indexOf(vl.vehicleModel) === idx).sort()
      .map(vl => {
        return <FilterPanelTypes.IFilterTreeNode>{
          display: vl.vehicleModel,
          value: vl.vehicleModel,
          orgFilterValue: { display: vl.vehicleModel, value: vl.vehicleModel, entityType: 'model', levelTypeId: 1 },
        };
      });
  }

  getDefaultVehicleLookupFilterValue(): FilterModelTypes.OrgFilterValue {
    // default is always all classes
    return { levelTypeId: 1, entityType: 'allclasses', display: 'All Classes', value: 'All Classes' };
  }

  getDefaultOrgFilterValue(): FilterModelTypes.OrgFilterValue {
    // set org level defaults
    let orgLookup: Models.IOrgLookup;
    let orgLookupTypeId: number = this.getDefaultOrgLevelTypeIdForUser(this.user);

    if (!!this.user.dealerGroup || this.configService.role.isDealerGroupRole(this.user.role)) {
      orgLookup = this.config.dealerGroupLookups.filter(ol => ol.orgCode1 === this.user.roleEntity)[0];

      return { levelTypeId: orgLookup.orgLookupTypeId, entityType: 'orgcode1', display: orgLookup.orgCode1Name, value: orgLookup.orgCode1 };
    }
    else {
      if (this.configService.role.isDealerRole(this.user.role)) {

        orgLookup = this.config.orgLookups.filter(
          ol => ol.dealerCode === this.user.roleEntity && ol.orgLookupTypeId === orgLookupTypeId
        )[0];

        return {
          levelTypeId: !!orgLookup ? orgLookup.orgLookupTypeId : orgLookupTypeId,
          entityType: 'dealer',
          display: `${this.configService.org.getOrgLookupTypeOrDefault(orgLookup.orgLookupTypeId).name} - ${orgLookup.dealerName}`,
          value: orgLookup.dealerCode
        };
      }
      else if (this.configService.role.isOrg1Role(this.user.role)) {
        orgLookup = this.config.orgLookups.filter(
          ol => ol.orgCode1 === this.user.roleEntity && ol.orgLookupTypeId === orgLookupTypeId
        )[0];

        return {
          levelTypeId: !!orgLookup ? orgLookup.orgLookupTypeId : orgLookupTypeId,
          entityType: 'orgcode1',
          display: orgLookup.orgCode1Name,
          value: orgLookup.orgCode1
        };
      }
      else if (this.configService.role.isOrg2Role(this.user.role)) {
        orgLookup = this.config.orgLookups.filter(
          ol => ol.orgCode2 === this.user.roleEntity && ol.orgLookupTypeId === orgLookupTypeId
        )[0];

        return {
          levelTypeId: !!orgLookup ? orgLookup.orgLookupTypeId : orgLookupTypeId,
          entityType: 'orgcode2',
          display: orgLookup.orgCode2Name,
          value: orgLookup.orgCode2
        };
      }
      else if (this.configService.role.isOrg3Role(this.user.role)) {
        orgLookup = this.config.orgLookups.filter(
          ol => ol.orgCode3 === this.user.roleEntity && ol.orgLookupTypeId === orgLookupTypeId
        )[0];
        return {
          levelTypeId: !!orgLookup ? orgLookup.orgLookupTypeId : orgLookupTypeId,
          entityType: 'orgcode3',
          display: orgLookup.orgCode3Name,
          value: orgLookup.orgCode3
        };
      }
      else if (this.configService.role.isOrg4Role(this.user.role)) {
        orgLookup = this.config.orgLookups.filter(
          ol => ol.orgCode4 === this.user.roleEntity && ol.orgLookupTypeId === orgLookupTypeId
        )[0];
        return {
          levelTypeId: !!orgLookup ? orgLookup.orgLookupTypeId : orgLookupTypeId,
          entityType: 'orgcode4',
          display: orgLookup.orgCode4Name,
          value: orgLookup.orgCode4
        };
      }
      else if (this.configService.role.isOrg5Role(this.user.role)) {
        orgLookup = this.config.orgLookups.filter(
          ol => ol.orgCode5 === this.user.roleEntity && ol.orgLookupTypeId === orgLookupTypeId
        )[0];
        return {
          levelTypeId: !!orgLookup ? orgLookup.orgLookupTypeId : orgLookupTypeId,
          entityType: 'orgcode5',
          display: orgLookup.orgCode5Name,
          value: orgLookup.orgCode5
        };
      }
      else {
        const ol = this.configService.org.getOrgLookupTypeOrDefault(orgLookupTypeId);

        return {
          levelTypeId: ol.orgLookupTypeId,
          entityType: 'national',
          display: ol.name,
          value: ol.name
        };
      }
    }
  }

  getDefaultOrgLevelTypeIdForUser(user: Models.IAuthenticationInfo): number {
    let userOrgLookupTypeIds = [];

    (user.orgLookupTypeIds || '').split(",").forEach(id => {
      if (!!parseInt(id, 10)) {
        userOrgLookupTypeIds.push(parseInt(id, 10))
      }
    });

    // SSO Users don't have specific orgLookupType associations, so we need to determine this manually
    if (userOrgLookupTypeIds.length > 0) {
      return userOrgLookupTypeIds[0];
    }
    else if (this.configService.role.isDealerRole(user.role)) {

      let dealerOrgLookups = this.config.orgLookups.filter(ol => ol.dealerCode == user.roleEntity
        && this.configService.org.IsNationalOrgLookupType(ol.orgLookupTypeId)
      ) || [];
      if (dealerOrgLookups.length > 0) {
        if ((this.configService.org.dealerUserOrgPriorityIds || []).length > 0) {
          return this.sortByOrgPriority(dealerOrgLookups);
        } else {
          return dealerOrgLookups[0].orgLookupTypeId;
        }
      }
    }
    else if (this.configService.role.isDealerGroupRole(user.role)) {
      return this.configService.org.orgConfig.dealerGroupOrgLookupTypeIds[0];
    }

    // If a set of valid OrgLookupTypes is not present and it is not a dealer user: use the system default since this assumes they have no restrictions
    return this.configService.org.defaultOrgLookupType().orgLookupTypeId;
  }

  sortByOrgPriority(orgLookups: Models.IOrgLookup[]): number {
    return orgLookups.sort((a, b) => this.configService.org.dealerUserOrgPriorityIds.indexOf(a.orgLookupTypeId) - this.configService.org.dealerUserOrgPriorityIds.indexOf(b.orgLookupTypeId))[0].orgLookupTypeId;
  }

  updateOptions(options: FilterOptions) {
    this.store$.dispatch(FilterActions.updateOptions({ options }));
  }

  /// All the stuff used to populate the pop-panels in filters

  getInputFilterModel = (filterValues: FilterPanelTypes.FilterDataType): FilterPanelTypes.PanelInputData => ({ inputData: filterValues });


  getOrgLookupFilterPanelData(locale: string): Observable<FilterPanelTypes.PanelInputData> {
    return this.getOrgLookupFilterNodes()
      .pipe(
        withLatestFrom(this.store$.select(AppSelectors.selectCurrentRouteData)),
        map(([nodes, routeData]) => {
          const userOrgTypes = nodes.map(n => n.orgFilterValue.levelTypeId);
          const reportConfig = this.configService.filter.filterConfig.filterReportConfigs[routeData.reportName];
          const filteredNodes: FilterPanelTypes.IFilterTreeNode[] = [];
          
          nodes.map(d => d.display = this.sharedTranslationService.getLabelTranslation(d.display, locale));
          nodes.map(d => d.children?.map(c => c.display = this.sharedTranslationService.getLabelTranslation(c.display, locale)));

          (userOrgTypes || reportConfig.orgTypes || environment.defaultOrgLevelTypeIds).forEach(
            orgLookupTypeId => {
              filteredNodes.push(
                ...[
                  nodes.find(
                    n => n.orgFilterValue.levelTypeId === orgLookupTypeId
                  )
                ]
              );
            }
          );

          return this.getInputFilterModel(filteredNodes);
        })
      );
  }


  getVehicleLookupFilterPanelData(): Observable<FilterPanelTypes.PanelInputData> {
    return combineLatest([
      this.store$.select(AppSelectors.selectCurrentRouteData),
      this.store$.select(AppSelectors.selectVehicleLookups)])
      .pipe(
        map(([routeData, vehicleLookups]) => {
          const nodes = this.getVehicleLookupFilterNodes(vehicleLookups);
          const reportConfig = this.configService.filter.filterConfig.filterReportConfigs[routeData.reportName];
          const filteredNodes: FilterPanelTypes.IFilterTreeNode[] = [];
          (reportConfig.orgTypes || environment.defaultOrgLevelTypeIds).forEach(orgLookupTypeId => {
            filteredNodes.push(...[nodes.find(n => n.orgFilterValue.levelTypeId === orgLookupTypeId)]);
          });

          return this.getInputFilterModel(filteredNodes);
        })
      );
  }

  // If you use this, make sure the filter.config file uses strings for the default values instead of numbers!
  getKeyValueFilterPanelData(filter: string, locale: string): Observable<FilterPanelTypes.PanelInputData> {
    return this.http.get<{ items: { display: string, value: string }[] }>(`${environment.baseV5ApiUri}/filter/items?filter=${filter}`)
      .pipe(
        map(result => this.getInputFilterModel(result.items.map(item => ({ display: this.sharedTranslationService.getLabelTranslation(item.display, locale), value: item.value }))))
      );
  }

  getDeviceTypeFilterPanelData(locale: string) {
    return this.store$.select(AppSelectors.selectDeviceTypes)
      .pipe(
        map(deviceTypes => this.getInputFilterModel(deviceTypes.map(deviceType => ({ display: this.labelTranslator(deviceType.name), value: deviceType.deviceTypeId }))))
      );
  }

  getLeadTypeFilterPanelData(locale: string) {
    return this.store$.select(AppSelectors.selectLeadTypes)
      .pipe(
        map(leadTypes => this.getInputFilterModel(leadTypes.map(leadType => ({ display: this.labelTranslator(leadType.name), value: leadType.leadTypeId }))))
      );
  }

  getProfitCenterFilterPanelData() {
    return this.store$.select(AppSelectors.selectProfitCenters)
      .pipe(
        map(profitCenters => this.getInputFilterModel(profitCenters.map(profitCenter => ({ display: profitCenter.name, value: profitCenter.profitCenterId }))))
      );
  }

  getSaleTypeFilterPanelData(locale: string) {
    return this.store$.select(AppSelectors.selectSaleTypes)
      .pipe(
        map(saleTypes => this.getInputFilterModel(saleTypes.map(saleType => ({ display: this.sharedTranslationService.getLabelTranslation(saleType.name, locale), value: saleType.saleTypeId }))))
      );
  }

  getSourceTypeFilterPanelData(locale: string) {
    return this.store$.select(AppSelectors.selectSourceTypes)
      .pipe(
        map(sourceTypes => this.getInputFilterModel(sourceTypes.map(sourceType => ({ display: this.labelTranslator(sourceType.name), value: sourceType.sourceTypeId }))))
      );
  }

  getTraitFilterPanelData(locale: string) {
    return this.store$.select(AppSelectors.selectTraits)
      .pipe(
        map(traits => this.getInputFilterModel(traits.map(trait => ({ display: this.labelTranslator(trait.name), value: trait.traitId }))))
      );
  }

  getVehicleMakeFilterPanelData(locale: string) {
    return this.store$.select(AppSelectors.selectVehicleMakes)
      .pipe(
        map(makes => this.getInputFilterModel(makes.map(make => ({ display: this.sharedTranslationService.getLabelTranslation(make.name, locale), value: make.vehicleMakeId }))))
      );
  }

  getVehicleClassFilterPanelData() {
    return this.store$.select(AppSelectors.selectVehicleClasses)
      .pipe(
        map(classes => this.getInputFilterModel(classes.map(c => ({ display: c, value: c }))))
      );
  }

  getVehicleModelFilterPanelData(locale: string) {
    return this.store$.select(AppSelectors.selectVehicleModels)
      .pipe(
        map(models => this.getInputFilterModel(models.map(model => ({ display: this.sharedTranslationService.getLabelTranslation(model.modelName, locale), value: model.vehicleModelId }))))
      );
  }

  getWebsiteProviderFilterPanelData() {
    return this.store$.select(AppSelectors.selectWebsiteProviders)
      .pipe(
        map(websiteProviders => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['website-provider'].available;
          return !!available ? websiteProviders.filter(wp => available.includes(wp.providerId)) : websiteProviders;
        }),
        map(websiteProviders => this.getInputFilterModel(websiteProviders.map(websiteProvider => ({ display: websiteProvider.name, value: websiteProvider.providerId }))))
      );
  }

  getChatProviderFilterPanelData() {
    return this.store$.select(AppSelectors.selectChatProviders)
      .pipe(
        map(chatProviders => this.getInputFilterModel(chatProviders.map(chatProvider => ({ display: chatProvider.name, value: chatProvider.providerId }))))
      );
  }

  getDealerSizeFilterPanelData() {
    return this.store$.select(AppSelectors.selectDealerSizes)
      .pipe(
        map(dealerSizes => this.getInputFilterModel(dealerSizes.map(dealerSize => ({ display: dealerSize.name, value: dealerSize.id }))))
      );
  }

  getSalesUnitFilterPanelData() {
    return this.store$.select(AppSelectors.selectSalesUnits)
      .pipe(
        map(salesUnits => this.getInputFilterModel(salesUnits.map(salesUnit => ({ display: salesUnit.name, value: salesUnit.salesUnitId }))))
      )
  }

  getProductLineFilterPanelData() {
    return this.store$.select(AppSelectors.selectProductLines)
      .pipe(
        map(productLines => this.getInputFilterModel(productLines.map(productLine => ({ display: productLine.name, value: productLine.productLineId }))))
      )
  }

  getTradeInProviderFilterPanelData() {
    return this.store$.select(AppSelectors.selectTradeInProviders)
      .pipe(
        map(tradeInProviders => this.getInputFilterModel(tradeInProviders.map(tradeInProvider => ({ display: tradeInProvider.name, value: tradeInProvider.providerId }))))
      )
  }

  getServiceSchedulerProviderFilterPanelData() {
    return this.store$.select(AppSelectors.selectServiceSchedulerProviders)
      .pipe(
        map(serviceSchedulerProviders => this.getInputFilterModel(serviceSchedulerProviders.map(serviceSchedulerProvider => ({ display: serviceSchedulerProvider.name, value: serviceSchedulerProvider.providerId }))))
      )
  }

  getDigAdProviderFilterPanelData() {
    return this.store$.select(AppSelectors.selectDigAdProviders)
      .pipe(
        map(digAdProviders => this.getInputFilterModel(digAdProviders.map(digAdProvider => ({ display: digAdProvider.name, value: digAdProvider.providerId }))))
      );
  }

  getDigitalRetailingProviderFilterPanelData() {
    return this.store$.select(AppSelectors.selectProviders)
      .pipe(
        map(providers => this.getInputFilterModel(providers.filter(p => !!p.isDRProvider).map(p => ({ display: p.name, value: p.providerId }))))
      );
  }

  getReputationPlatformFilterPanelData() {
    return this.store$.select(AppSelectors.selectReputationPlatforms)
      .pipe(
        map(reputationPlatforms => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['reputation-platform']?.available;
          return !!available ? reputationPlatforms.filter(wp => available.includes(wp.reputationPlatformId)) : reputationPlatforms;
        }),
        map(reputationPlatforms => this.getInputFilterModel(reputationPlatforms.map(reputationPlatform => ({ display: reputationPlatform.name, value: reputationPlatform.reputationPlatformId }))))
      );
  }

  getReputationProviderFilterPanelData() {
    return this.store$.select(AppSelectors.selectReputationProviders)
      .pipe(
        map(reputationProviders => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['reputation-provider'].available;
          return !!available ? reputationProviders.filter(wp => available.includes(wp.providerId)) : reputationProviders;
        }),
        map(reputationProviders => this.getInputFilterModel(reputationProviders.map(reputationProvider => ({ display: reputationProvider.name, value: reputationProvider.providerId }))))
      );
  }

  getSocialMediaProviderFilterPanelData() {
    return this.store$.select(AppSelectors.selectSocialMediaProviders)
      .pipe(
        map(socialMediaProviders => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['social-media-provider'].available;
          return !!available ? socialMediaProviders.filter(wp => available.includes(wp.providerId)) : socialMediaProviders;
        }),
        map(socialMediaProviders => this.getInputFilterModel(socialMediaProviders.map(socialMediaProvider => ({ display: socialMediaProvider.name, value: socialMediaProvider.providerId }))))
      );
  }

  getSocialMediaPlatformFilterPanelData() {
    return this.store$.select(AppSelectors.selectSocialMediaPlatforms)
      .pipe(
        map(socialMediaPlatforms => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['social-media-platform'].available;
          return !!available ? socialMediaPlatforms.filter(wp => available.includes(wp.socialMediaPlatformId)) : socialMediaPlatforms;
        }),
        map(socialMediaPlatforms => this.getInputFilterModel(socialMediaPlatforms.map(socialMediaPlatform => ({ display: socialMediaPlatform.name, value: socialMediaPlatform.socialMediaPlatformId }))))
      );
  }

  getChannelFilterPanelData() {
    return this.store$.select(AppSelectors.selectChannels)
      .pipe(
        map(channels => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['channel'].available;
          return !!available ? channels.filter(c => available.includes(c.channelId)) : channels;
        }),
        map(channels => this.getInputFilterModel(channels.map(c => ({ display: this.labelTranslator(c.name), value: c.channelId }))))
      );
  }

  getBrandFilterPanelData() {
    return this.store$.select(AppSelectors.selectBrands)
      .pipe(
        map(brands => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['brand'].available;
          return !!available ? brands.filter(c => available.includes(c.brandId)) : brands;
        }),
        map(brands => this.getInputFilterModel(brands.map(c => ({ display: c.name, value: c.brandId }))))
      );
  }

  getCampaignTypeFilterPanelData() {
    return this.store$.select(AppSelectors.selectCampaignTypes)
      .pipe(
        map(campaignTypes => this.getInputFilterModel(campaignTypes.map(c => ({ display: c.name, value: c.campaignTypeId }))))
      );
  }

  getAudienceSegmentFilterPanelData() {
    return this.store$.select(AppSelectors.selectAudienceSegments)
      .pipe(
        map(audienceSegments => this.getInputFilterModel(audienceSegments.map(c => ({ display: c.name, value: c.audienceSegmentId }))))
      );
  }

  getJourneySegmentFilterPanelData() {
    return this.store$.select(AppSelectors.selectJourneySegments)
      .pipe(
        map(journeySegments => this.getInputFilterModel(journeySegments.map(c => ({ display: c.name, value: c.journeySegmentId }))))
      );
  }

  getVehicleTypeFilterPanelData() {
    return this.store$.select(AppSelectors.selectVehicleTypes)
      .pipe(
        map(vehcileTypes => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['vehicle-type'].available;
          return !!available ? vehcileTypes.filter(c => available.includes(c.id)) : vehcileTypes;
        }),
        map(vehcileTypes => this.getInputFilterModel(vehcileTypes.map(c => ({ display: c.name, value: c.id }))))
      );
  }

  getDealTypeFilterPanelData() {
    return this.store$.select(AppSelectors.selectDealTypes)
      .pipe(
        map(dealTypes => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['deal-type'].available;
          return !!available ? dealTypes.filter(c => available.includes(c.id)) : dealTypes;
        }),
        map(dealTypes => this.getInputFilterModel(dealTypes.map(c => ({ display: c.name, value: c.id }))))
      );
  }

  getLeadGateFilterPanelData() {
    return this.store$.select(AppSelectors.selectLeadGates)
      .pipe(
        map(leadGates => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['lead-gate'].available;
          return !!available ? leadGates.filter(c => available.includes(c.id)) : leadGates;
        }),
        map(leadGates => this.getInputFilterModel(leadGates.map(c => ({ display: c.name, value: c.id }))))
      );
  }

  getRoleFilterPanelData() {
    return this.store$.select(AppSelectors.selectRoles)
      .pipe(
        map(roles => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['role'].available;
          return !!available ? roles.filter(r => available.includes(r.roleId)) : roles;
        }),
        map(roles => this.getInputFilterModel(roles.map(role => ({ display: role.name, value: role.roleId }))))
      );
  }

  getFlatPageFilterPanelData() {
    return this.store$.select(AppSelectors.selectPages)
      .pipe(
        map(pages => {
          const available = this.configService.filter.filterConfig.FILTER_CONFIG['page'].available;
          return !!available ? pages.filter(r => available.includes(r.pageId)) : pages;
        }),
        map(pages => this.getInputFilterModel(pages.map(page => ({ display: `${page.pageTypeName}: ${page.name}`, value: page.pageId }))))
      );
  }

  // Come back to this at some point when we can implement a better multi-select hierarchical filter panel
  getHierarchicalPageFilterPanelData() {
    return this.store$.select(AppSelectors.selectPages)
      .pipe(
        map(pages => {
          const pageFilterTree: FilterPanelTypes.IFilterTreeNode[] = [];
          Object.keys(Enums.pageTypes).forEach(key => {
            const pageType = Enums.pageTypes[key];
            pageFilterTree.push(<FilterPanelTypes.IFilterTreeNode>{
              display: pageType.name,
              formatDisplay: this.pageFilterNodeFormatter,
              value: +(pageType.pageTypeId + '000'),
              collectionFilterValue: { display: pageType.name, value: pageType.pageTypeId, entityType: 'page-type', levelTypeId: 1 },
              children: pages.filter(page => page.pageTypeId === pageType.pageTypeId).map(page => {
                return <FilterPanelTypes.IFilterTreeNode>{
                  display: page.name,
                  value: page.pageId,
                  formatDisplay: this.pageFilterNodeFormatter,
                  collectionFilterValue: { display: page.name, value: page.pageId, entityType: 'page', levelTypeId: 1 },
                };
              })
            });
          });

          return this.getInputFilterModel(pageFilterTree);
        })
      );
  }


  getAllChannelIds(): number[] {
    const channelIds = [];
    const channelNames = Object.keys(Enums.channels);

    channelNames.forEach(p => {
      const channel = Enums.channels[p];
      if (channel.channelId !== Enums.channels.allChannels.channelId) {
        channelIds.push(channel.channelId);
      }
    });

    return channelIds;
  }

  getEntityTypeDisplayName(entityType: string) {
    return this.labelTranslator(this.configService.org.orgConfig.entityTypeName.get(entityType));
  }
}
