import {
    IDefaultTableColumnConfig,
    IWebsiteSummaryConfig,
    IDualAxisLineGraphConfig,
    ICardHeaderConfig,
    INameValuePair} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteSummaryConfig implements IWebsiteSummaryConfig {
    reportTitle = 'Website Summary';
    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Summary Data',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.websiteSummaryHelp,
        helpTextTitle: 'Website Summary',
        exportName: 'Website Summary - Summary Data',
        metricDisplayModes: ['POP', 'YOY', 'Trend']
      };
      public metricDisplayModes = [
        { name: 'POP', value: 'MOM' },
        { name: 'YOY', value: 'YOY' }
      ];

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            // Visits
            {
                id: Enums.websiteMetrics.totalVisits.metricId,
                displayName: 'Website Visits',
                propertyName: Enums.websiteMetrics.totalVisits.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.uniqueVisits.metricId,
                displayName: 'Unique Visitors',
                propertyName: Enums.websiteMetrics.uniqueVisits.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.pageViews.metricId,
                displayName: 'Total Page Views',
                propertyName: Enums.websiteMetrics.pageViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.servicePageViews.metricId,
                displayName: Enums.websiteMetrics.servicePageViews.name,
                propertyName: Enums.websiteMetrics.servicePageViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // Engagement
            {
                id: Enums.websiteMetrics.engagements.metricId,
                displayName: Enums.websiteMetrics.engagements.name,
                propertyName: Enums.websiteMetrics.engagements.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.engagementRate.metricId,
                displayName: Enums.websiteMetrics.engagementRate.name,
                propertyName: Enums.websiteMetrics.engagementRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Actions
            {
                id: Enums.websiteMetrics.actions.metricId,
                displayName: Enums.websiteMetrics.actions.name,
                propertyName: Enums.websiteMetrics.actions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.actionRate.metricId,
                displayName: Enums.websiteMetrics.actionRate.name,
                propertyName: Enums.websiteMetrics.actionRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Avg. Time on Site
            {
                id: Enums.websiteMetrics.avgTimeOnSite.metricId,
                displayName: 'Time on Site',
                propertyName: Enums.websiteMetrics.avgTimeOnSite.nameKey,
                metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
            },
            // Forms
            {
                id: Enums.websiteMetrics.formSubmissions.metricId,
                displayName: Enums.websiteMetrics.formSubmissions.name,
                propertyName: Enums.websiteMetrics.formSubmissions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.formConversionRate.metricId,
                displayName: Enums.websiteMetrics.formConversionRate.name,
                propertyName: Enums.websiteMetrics.formConversionRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Phone
            {
                id: Enums.websiteMetrics.phoneCalls.metricId,
                displayName: Enums.websiteMetrics.phoneCalls.name,
                propertyName: Enums.websiteMetrics.phoneCalls.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.phoneConversionRate.metricId,
                displayName: Enums.websiteMetrics.phoneConversionRate.name,
                propertyName: Enums.websiteMetrics.phoneConversionRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Total Contact
            {
                id: Enums.websiteMetrics.totalContacts.metricId,
                displayName: Enums.websiteMetrics.totalContacts.name,
                propertyName: Enums.websiteMetrics.totalContacts.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.totalContactRate.metricId,
                displayName: Enums.websiteMetrics.totalContactRate.name,
                propertyName: Enums.websiteMetrics.totalContactRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Click to Call
            {
                id: Enums.websiteMetrics.clickToCalls.metricId,
                displayName: Enums.websiteMetrics.clickToCalls.name,
                propertyName: Enums.websiteMetrics.clickToCalls.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // Bounce Rate
            {
                id: Enums.websiteMetrics.bounceRate.metricId,
                displayName: Enums.websiteMetrics.bounceRate.name,
                propertyName: Enums.websiteMetrics.bounceRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // VDPs
            {
                id: Enums.websiteMetrics.newInventoryDetailViews.metricId,
                displayName: Enums.websiteMetrics.newVdpViews.name,
                propertyName: Enums.websiteMetrics.newInventoryDetailViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.usedInventoryDetailViews.metricId,
                displayName: Enums.websiteMetrics.usedVdpViews.name,
                propertyName: Enums.websiteMetrics.usedInventoryDetailViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.cpoInventoryDetailViews.metricId,
                displayName: Enums.websiteMetrics.cpoVdpViews.name,
                propertyName: Enums.websiteMetrics.cpoInventoryDetailViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            }
        ],
        defaultMetricOneId: Enums.websiteMetrics.totalVisits.metricId,
        defaultMetricTwoId: Enums.websiteMetrics.pageViews.metricId,
        metricOneColorHexCode: '#4875b4',
        metricTwoColorHexCode: '#00aced'
    };
    public trendTableMetrics: INameValuePair[] = [
      {name: 'Website Visits', value: 'totalVisits'},
      {name: 'Unique Visitors', value: 'uniqueVisits'},
      {name: 'Page Views', value: 'pageViews'},
      {name: 'Service Page Views', value: 'servicePageViews'},
      {name: 'Avg. Time on Site', value: 'averageTimeOnSite'},
      {name: 'Engagements', value: 'engagements'},
      {name: 'Engagement Rate', value: 'engagementRate'},
      {name: 'Actions', value: 'actions'},
      {name: 'Action Rate', value: 'actionRate'},
      {name: 'Form Submissions', value: 'formSubmissions'},
      {name: 'Form Conversion Rate', value: 'formConversionRate'},
      {name: 'Phone Calls', value: 'phoneCalls'},
      {name: 'Phone Conversion Rate', value: 'phoneConversionRate'},
      {name: 'Total Contacts', value: 'totalContacts'},
      {name: 'Total Contact Rate', value: 'totalContactRate'},
      {name: 'Click to Call', value: 'clickToCalls'},
      {name: 'Bounce Rate', value: 'bounceRate'},
      {name: 'New VDP Views', value: 'newInventoryDetailViews'},
      {name: 'Used VDP Views', value: 'usedInventoryDetailViews'},
      {name: 'CPO VDP Views', value: 'cpoInventoryDetailViews'},
    ];
    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        {
            show: false,
            print: true,
            header: 'Dealer Name',
            columnDef: 'displayName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
        },
        // Visits
        {
            show: true,
            header: 'Website Visits',
            columnDef: 'totalVisits',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period Website Visits',
            columnDef: 'totalVisitsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Website Visits POP',
            columnDef: 'totalVisitsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Website Visits',
            columnDef: 'totalVisitsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Website Visits YOY',
            columnDef: 'totalVisitsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Unique Visits
        {
            show: true,
            header: 'Unique Visitors',
            columnDef: 'uniqueVisits',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period Unique Visitors',
            columnDef: 'uniqueVisitsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Unique Visitors POP',
            columnDef: 'uniqueVisitsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Unique Visitors',
            columnDef: 'uniqueVisitsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Unique Visitors YOY',
            columnDef: 'uniqueVisitsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Total Page Views
        {
            show: true,
            header: 'Total Page Views',
            columnDef: 'pageViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period Total Page Views',
            columnDef: 'pageViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Total Page Views POP',
            columnDef: 'pageViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Total Page Views',
            columnDef: 'pageViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Total Page Views YOY',
            columnDef: 'pageViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Total Service Page Views
          {
            show: true,
            header: 'Service Page Views',
            columnDef: 'servicePageViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            show: false,
            header: 'Previous Period Service Page Views',
            columnDef: 'servicePageViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            show: false,
            header: 'Service Page Views POP',
            columnDef: 'servicePageViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          {
            show: false,
            header: 'Previous Year Service Page Views',
            columnDef: 'servicePageViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            show: false,
            header: 'Service Page Views YOY',
            columnDef: 'servicePageViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
        // Engagements
        {
            show: true,
            header: 'Engagements',
            columnDef: 'engagements',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period Engagements',
            columnDef: 'engagementsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Engagements POP',
            columnDef: 'engagementsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Engagements',
            columnDef: 'engagementsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Engagements YOY',
            columnDef: 'engagementsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Engagement Rate
        {
            show: true,
            header: 'Engagement Rate',
            columnDef: 'engagementRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Period Engagement Rate',
            columnDef: 'engagementRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Engagement Rate POP',
            columnDef: 'engagementRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Engagement Rate',
            columnDef: 'engagementRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Engagement Rate YOY',
            columnDef: 'engagementRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Actions
        {
            show: true,
            header: 'Actions',
            columnDef: 'actions',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period Actions',
            columnDef: 'actionsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Actions POP',
            columnDef: 'actionsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Actions',
            columnDef: 'actionsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Actions YOY',
            columnDef: 'actionsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Action Rate
        {
            show: true,
            header: 'Action Rate',
            columnDef: 'actionRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Period Action Rate',
            columnDef: 'actionRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Action Rate POP',
            columnDef: 'actionRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Action Rate',
            columnDef: 'actionRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Action Rate YOY',
            columnDef: 'actionRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        // Avg Time on Site
        {
            show: true,
            header: 'Time On Site',
            columnDef: 'averageTimeOnSite',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
        },
        {
            show: false,
            header: 'Time On Site POP',
            columnDef: 'averageTimeOnSiteMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Prev. Period Time On Site',
            columnDef: 'averageTimeOnSitePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
        },
        {
            show: false,
            header: 'Time On Site YOY',
            columnDef: 'averageTimeOnSiteYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Prev. Year Time On Site',
            columnDef: 'averageTimeOnSitePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
        },

        // Form Submissions
        {
            show: true,
            header: 'Form Submissions',
            columnDef: 'formSubmissions',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period Form Submissions',
            columnDef: 'formSubmissionsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Form Submissions POP',
            columnDef: 'formSubmissionsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Form Submissions',
            columnDef: 'formSubmissionsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Form Submissions YOY',
            columnDef: 'formSubmissionsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Form Conversion Rate
        {
            show: true,
            header: 'Form Conversion Rate',
            columnDef: 'formConversionRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Period Form Conversion Rate',
            columnDef: 'formConversionRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Form Conversion Rate POP',
            columnDef: 'formConversionRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Form Conversion Rate',
            columnDef: 'formConversionRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Form Conversion Rate YOY',
            columnDef: 'formConversionRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Phone Calls
        {
            show: true,
            header: 'Phone Calls',
            columnDef: 'phoneCalls',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period Phone Calls',
            columnDef: 'phoneCallsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Phone Calls POP',
            columnDef: 'phoneCallsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Phone Calls',
            columnDef: 'phoneCallsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Phone Calls YOY',
            columnDef: 'phoneCallsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Phone Conversion Rate
        {
            show: true,
            header: 'Phone Conversion Rate',
            columnDef: 'phoneConversionRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Period Phone Conversion Rate',
            columnDef: 'phoneConversionRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Phone Conversion Rate POP',
            columnDef: 'phoneConversionRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Phone Conversion Rate',
            columnDef: 'phoneConversionRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Phone Conversion Rate YOY',
            columnDef: 'phoneConversionRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Total Contacts
        {
            show: true,
            header: 'Total Contacts',
            columnDef: 'totalContacts',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period Total Contacts',
            columnDef: 'totalContactsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Total Contacts POP',
            columnDef: 'totalContactsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Total Contacts',
            columnDef: 'totalContactsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Total Contacts YOY',
            columnDef: 'totalContactsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Total Contact Rate
        {
            show: true,
            header: 'Total Contact Rate',
            columnDef: 'totalContactRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Period Total Contact Rate',
            columnDef: 'totalContactRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Total Contact Rate POP',
            columnDef: 'totalContactRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Total Contact Rate',
            columnDef: 'totalContactRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Total Contact Rate YOY',
            columnDef: 'totalContactRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Click to Call
        {
            show: true,
            header: 'Click to Call',
            columnDef: 'clickToCalls',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period Click to Call',
            columnDef: 'clickToCallsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Click to Call POP',
            columnDef: 'clickToCallsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Click to Call',
            columnDef: 'clickToCallsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Click to Call YOY',
            columnDef: 'clickToCallsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Bounce Rate
        {
            show: true,
            header: 'Bounce Rate',
            columnDef: 'bounceRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Period Bounce Rate',
            columnDef: 'bounceRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Bounce Rate POP',
            columnDef: 'bounceRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Bounce Rate',
            columnDef: 'bounceRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Bounce Rate YOY',
            columnDef: 'bounceRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // New VDP
        {
            show: true,
            header: 'New VDP Views',
            columnDef: 'newInventoryDetailViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period New VDP Views',
            columnDef: 'newInventoryDetailViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'New VDP Views POP',
            columnDef: 'newInventoryDetailViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year New VDP Views',
            columnDef: 'newInventoryDetailViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'New VDP Views YOY',
            columnDef: 'newInventoryDetailViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Used VDPs
        {
            show: true,
            header: 'Used VDP Views',
            columnDef: 'usedInventoryDetailViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period Used VDP Views',
            columnDef: 'usedInventoryDetailViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Used VDP Views POP',
            columnDef: 'usedInventoryDetailViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year Used VDP Views',
            columnDef: 'usedInventoryDetailViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Used VDP Views YOY',
            columnDef: 'usedInventoryDetailViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // CPO VDPs
        {
            show: true,
            header: 'CPO VDP Views',
            columnDef: 'cpoInventoryDetailViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'Previous Period CPO VDP Views',
            columnDef: 'cpoInventoryDetailViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'CPO VDP Views POP',
            columnDef: 'cpoInventoryDetailViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Year CPO VDP Views',
            columnDef: 'cpoInventoryDetailViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: false,
            header: 'CPO VDP Views YOY',
            columnDef: 'cpoInventoryDetailViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
    ];
}
