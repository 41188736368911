import {
    IChatOverviewConfig,
    IChatOverviewBillboardConfig,
    ICardHeaderConfig,
    IMultiLevelDualAxisLineGraphConfig,
    IDefaultTableColumnConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class ChatOverviewConfig implements IChatOverviewConfig {
    public reportTitle = 'Chat Overview';
    public hasChatHelpText = true;
    public metricDisplayModes = [
      { name: 'POP', value: 'MOM' },
      { name: 'YOY', value: 'YOY' }
    ];
    public previousDateColumnDisplayOverrides = {
      previousMonthDisplay: 'Prev. Period',
      momDisplay: 'POP'
    }
    public billboards: IChatOverviewBillboardConfig[] = [
        {
            title: 'Visits With Impressions',
            subtitle: '',
            iconClass: 'fa-user fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'visitsWithImpressions',
            metricMOMPropertyName: 'visitsWithImpressionsMOM',
            metricYOYPropertyName: 'visitsWithImpressionsYOY',
            metricPreviousMonthPropertyName: 'visitsWithImpressionsPreviousMonth',
            metricPreviousMTDPropertyName: 'visitsWithImpressionsPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Period',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'POP',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
            showTrendArrow: true,
            trendArrowDrivingPropertyName: 'visitsWithImpressionsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: 'ChatOverviewVisitsWithChatImpressionsHelp',
            helpTextTitle: 'Visits With Impressions'
        },
        {
            title: 'Chat Leads',
            subtitle: '',
            iconClass: 'fa-comments fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'leadVolume',
            metricMOMPropertyName: 'leadVolumeMOM',
            metricYOYPropertyName: 'leadVolumeYOY',
            metricPreviousMonthPropertyName: 'leadVolumePreviousMonth',
            metricPreviousMTDPropertyName: 'leadVolumePreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Period',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'POP',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
            showTrendArrow: true,
            trendArrowDrivingPropertyName: 'leadVolumeMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: 'ChatOverviewChatLeadsHelp',
            helpTextTitle: 'Chat Leads'
        },
        {
            title: 'Start Rate',
            subtitle: '',
            iconClass: 'fa-percent fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'startRate',
            metricMOMPropertyName: 'startRateMOM',
            metricYOYPropertyName: 'startRateYOY',
            metricPreviousMonthPropertyName: 'startRatePreviousMonth',
            metricPreviousMTDPropertyName: 'startRatePreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Period',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'POP',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
            showTrendArrow: true,
            trendArrowDrivingPropertyName: 'startRateMOM',
            metricFormatKey: constants.formatKeys.percentageOneDecimal,
            helpTextKey: 'ChatOverviewChatStartRateHelp',
            helpTextTitle: 'Start Rate'
        },
        {
            title: 'Engagement Rate',
            subtitle: '',
            iconClass: 'fa-percent fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'engagementRate',
            metricMOMPropertyName: 'engagementRateMOM',
            metricYOYPropertyName: 'engagementRateYOY',
            metricPreviousMonthPropertyName: 'engagementRatePreviousMonth',
            metricPreviousMTDPropertyName: 'engagementRatePreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Period',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'POP',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
            showTrendArrow: true,
            trendArrowDrivingPropertyName: 'engagementRateMOM',
            metricFormatKey: constants.formatKeys.percentageOneDecimal,
            helpTextKey: 'ChatOverviewChatEngagementRateHelp',
            helpTextTitle: 'Engagement Rate'
        },
    ];

    public kpiSheetOptions = {
        overview: [{
            name: 'providerId',
            label: 'Provider',
            isHeader: true
        }, {
            name: 'visitsWithImpressions',
            label: 'Visits With Impressions',
            format: constants.formatKeys.abbreviatedLocaleString
        }, {
            name: 'starts',
            label: 'Chats Started',
            format: constants.formatKeys.localeString
        }, {
            name: 'leadVolume',
            label: 'Chat Leads',
            format: constants.formatKeys.localeString
        }, {
            name: 'startRate',
            label: 'Start Rate',
            format: constants.formatKeys.percentageTwoDecimals
        }, {
            name: 'engagementRate',
            label: 'Engagement Rate',
            format: constants.formatKeys.percentageTwoDecimals
        }, {
            name: 'abandonRate',
            label: 'Abandoned Rate',
            format: constants.formatKeys.percentageTwoDecimals,
            reverseMetric: true
        }, {
            name: 'messagesPerChat',
            label: 'Avg. Messages/Chat',
            format: constants.formatKeys.roundToTenth
        }]
      };

    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Summary Data',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.chatSummaryHelp,
        helpTextTitle: 'Chat Summary',
        exportName: 'Chat Summary - Summary Data',
        metricDisplayModes: ['POP', 'YOY']
    };

    public trendGraphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [
            {
                id: Enums.chatMetrics.impressions.metricId,
                displayName: 'Chat Impressions',
                propertyName: Enums.chatMetrics.impressions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.chatMetrics.visitsWithImpressions.metricId,
                displayName: 'Visits With Impresions',
                propertyName: Enums.chatMetrics.visitsWithImpressions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.chatMetrics.chatsStarted.metricId,
                displayName: 'Chats Started',
                propertyName: Enums.chatMetrics.chatsStarted.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.chatMetrics.chatLeads.metricId,
                displayName: 'Chat Leads',
                propertyName: Enums.chatMetrics.chatLeads.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.chatMetrics.chatStartRate.metricId,
                displayName: 'Start Rate',
                propertyName: Enums.chatMetrics.chatStartRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.chatMetrics.chatsEngaged.metricId,
                displayName: 'Chat Engagements',
                propertyName: Enums.chatMetrics.chatsEngaged.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.chatMetrics.chatEngagementRate.metricId,
                displayName: 'Engagement Rate',
                propertyName: Enums.chatMetrics.chatEngagementRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.chatMetrics.chatsAbandoned.metricId,
                displayName: 'Chats Abandoned',
                propertyName: Enums.chatMetrics.chatsAbandoned.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.chatMetrics.chatAbandonRate.metricId,
                displayName: 'Abandon Rate',
                propertyName: Enums.chatMetrics.chatAbandonRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.chatMetrics.avgMessagesPerChat.metricId,
                displayName: 'Avg. Messages/Chat',
                propertyName: Enums.chatMetrics.avgMessagesPerChat.nameKey,
                metricFormatKey: constants.formatKeys.roundToHundreth
            },
        ],
        childMetrics: [
            {
                id: Enums.chatProviders.allProviders.providerId,
                displayName: Enums.chatProviders.allProviders.displayName,
                propertyName: Enums.chatProviders.allProviders.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 2,
                displayName: Enums.chatProviders.dealerInspire.displayName,
                propertyName: Enums.chatProviders.dealerInspire.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 15,
                displayName: Enums.chatProviders.activEngage.displayName,
                propertyName: Enums.chatProviders.activEngage.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 12,
                displayName: Enums.chatProviders.carNow.displayName,
                propertyName: Enums.chatProviders.carNow.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 18,
                displayName: Enums.chatProviders.livePerson.displayName,
                propertyName: Enums.chatProviders.livePerson.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 17,
                displayName: Enums.chatProviders.gubagoo.displayName,
                propertyName: Enums.chatProviders.gubagoo.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 19,
                displayName: Enums.chatProviders.podium.name,
                propertyName: Enums.chatProviders.podium.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 41,
                displayName: Enums.chatProviders.kenect.name,
                propertyName: Enums.chatProviders.kenect.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
        ],
        defaultChildrenIds: [ Enums.chatProviders.allProviders.providerId, Enums.chatProviders.allProviders.providerId ],
        defaultParentIds: [ Enums.chatMetrics.impressions.metricId, Enums.chatMetrics.chatsStarted.metricId ],
        metricOneColorHexCode: '#4875b4',
        metricTwoColorHexCode: '#00aced'
    };
    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        {
            show: false,
            print: true,
            header: 'Dealer Name',
            columnDef: 'displayName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
        },
        // Chat Impressions
        {
            show: true,
            header: 'Chat Impressions',
            columnDef: 'impressions', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Prev. Period Chat Impressions',
            columnDef: 'impressionsPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chat Impressions POP',
            columnDef: 'impressionsMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Chat Impressions',
            columnDef: 'impressionsPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chat Impressions YOY',
            columnDef: 'impressionsYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        // Visits With Impressions
        {
            show: true,
            header: 'Visits With Impressions',
            columnDef: 'visitsWithImpressions', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Prev. Period Visits With Impressions',
            columnDef: 'visitsWithImpressionsPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Visits With Impressions POP',
            columnDef: 'visitsWithImpressionsMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Visits With Impressions',
            columnDef: 'visitsWithImpressionsPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Visits With Impressions YOY',
            columnDef: 'visitsWithImpressionsYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        // Chats Started
        {
            show: true,
            header: 'Chats Started',
            columnDef: 'starts', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Prev. Period Chats Started',
            columnDef: 'startsPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chats Started POP',
            columnDef: 'startsMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Chats Started',
            columnDef: 'startsPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chats Started YOY',
            columnDef: 'startsYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        // Chats Started
        {
            show: true,
            header: 'Chat Leads',
            columnDef: 'leadVolume', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Period Chat Leads',
            columnDef: 'leadVolumePreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Chat Leads POP',
            columnDef: 'leadVolumeMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Chat Leads',
            columnDef: 'leadVolumePreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Chat Leads YOY',
            columnDef: 'leadVolumeYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Start Rate
        {
            show: true,
            header: 'Start Rate',
            columnDef: 'startRate', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Period Start Rate',
            columnDef: 'startRatePreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Start Rate POP',
            columnDef: 'startRateMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Start Rate',
            columnDef: 'startRatePreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Start Rate YOY',
            columnDef: 'startRateYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        // Chat Engagements
        {
            show: true,
            header: 'Chat Engagements',
            columnDef: 'engagements', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Prev. Period Chat Engagements',
            columnDef: 'engagementsPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chat Engagements POP',
            columnDef: 'engagementsMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Chat Engagements',
            columnDef: 'engagementsPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chat Engagements YOY',
            columnDef: 'engagementsYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        // Engagement Rate
        {
            show: true,
            header: 'Engagement Rate',
            columnDef: 'engagementRate', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Period Engagement Rate',
            columnDef: 'engagementRatePreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Engagement Rate POP',
            columnDef: 'engagementRateMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Engagement Rate',
            columnDef: 'engagementRatePreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Engagement Rate YOY',
            columnDef: 'engagementRateYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        // Chats Abandoned
        {
            show: true,
            header: 'Chats Abandoned',
            columnDef: 'abandons', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Prev. Period Chats Abandoned',
            columnDef: 'abandonsPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chats Abandoned POP',
            columnDef: 'abandonsMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Chats Abandoned',
            columnDef: 'abandonsPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chats Abandoned YOY',
            columnDef: 'abandonsYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        // Abandon Rate
        {
            show: true,
            header: 'Abandon Rate',
            columnDef: 'abandonRate', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Period Abandon Rate',
            columnDef: 'abandonRatePreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Abandon Rate POP',
            columnDef: 'abandonRateMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Abandon Rate',
            columnDef: 'abandonRatePreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Abandon Rate YOY',
            columnDef: 'abandonRateYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        // Avg. Messages/Chat
        {
            show: true,
            header: 'Avg. Messages/Chat',
            columnDef: 'messagesPerChat', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.roundToTenth, clickable: false },
        {
            show: false,
            header: 'Prev. Period Avg. Messages/Chat',
            columnDef: 'messagesPerChatPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.roundToTenth, clickable: false },
        {
            show: false,
            header: 'Avg. Messages/Chat POP',
            columnDef: 'messagesPerChatMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Avg. Messages/Chat',
            columnDef: 'messagesPerChatPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.roundToTenth, clickable: false },
        {
            show: false,
            header: 'Avg. Messages/Chat YOY',
            columnDef: 'messagesPerChatYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
    ];
}
