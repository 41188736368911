import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { select, Store } from '@ngrx/store';
import { selectLoggedInUser } from '../../_store/app.selectors';
import { map } from 'rxjs/operators';
import { AppState } from '../../_store/app-state.model';
import { environment } from '../../../environments/environment';
import { RoutingConfigService } from '../services/services-index';
import { IRouteConfig, IRouteDetails } from '../models/models-index';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private store: Store<AppState>,
		private authService: AuthenticationService,
		private router: Router,
		private readonly routingConfigService: RoutingConfigService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.store.pipe(
			select(selectLoggedInUser),
			map(user => {
				if (user === undefined || !user) {
					if (!!environment.loginRedirectUrl) {
						window.location.href = environment.loginRedirectUrl;
					} else {
						this.router.navigateByUrl('/login');
					}
					return false;
				}

				if (!route.data.allowAnonymous) {
					const role = user.role;

					if (!route.data.roles.find(r => r == role)) {
						console.log('Not authorized to access [' + route.url + ']');
						if (route.data.authFailRedirectTo) {
							this.router.navigateByUrl(route.data.authFailRedirectTo);
							return false;
						} else {
							this.router.navigateByUrl(this.routingConfigService.defaultRoute);
							return false;
						}
					}
				} else {
					const roleRoute = this.routingConfigService.routingConfig.routes.find(x => x.id === route.data.id);
					
					// if not configured then allow access for anonymous route
					if (!roleRoute || !roleRoute.roles) return true;

					const accessAllowedForCurrentRole = !!roleRoute.roles.find(x => x === user.role);
					
					if (!accessAllowedForCurrentRole) {
						// redirect to default route
						this.router.navigateByUrl(this.routingConfigService.defaultRoute);
						return false;
					}
				}
				return true;
			})
		);
	}

	canActivateChild(route: ActivatedRouteSnapshot) {
		var authInfo = this.authService.getUser();
		if (authInfo.isAuthenticated) {
			let canRoleAccessRoute = true
			if (route.data.id) {
				const routeDetails = this.findRouteById(route.data.id, this.routingConfigService.routingConfig.routes);
				if (routeDetails && routeDetails.roles) {
					canRoleAccessRoute = !!routeDetails.roles.find(r => r === authInfo.role);
				}
			}

			// check if can access by "app.routes.ts" rules
			const canAccessRoute = !route.data.roles || route.data.roles.find(r => r === authInfo.role);

			/* 
				TODO: check if can access by the client's routing config file...we don't typically store the route.data.roles in the root routing module
				(e.g. we are checking the digat-routing.module.ts but need to also check routing.[client].config.ts)

				Also - do we need two separate variables: canAccessRoute and canRoleAccessRoute or can we just use one?
			*/
			
			// If we've defined permissions for this route, check them
			if (!canAccessRoute || !canRoleAccessRoute) {
				console.log('Not authorized to access [' + route.url + ']');
				if (route.data.authFailRedirectTo) {
					this.router.navigateByUrl(route.data.authFailRedirectTo);
					return false;
				} else {
					this.router.navigateByUrl(this.routingConfigService.defaultRoute);
					return false;
				}
			}

			return true;
		} else {
			this.router.navigateByUrl('/login');
			return false;
		}
	}

	findRouteById(id: string, routes: IRouteConfig[] | IRouteDetails[]): IRouteConfig | IRouteDetails | null {
		for (let route of routes) {
			// Check if current route's id matches
			if (route.id === id) {
				return route;
			}

			// Check in the children routes if any
			if (route.children) {
				const foundInChildren = this.findRouteById(id, route.children);

				if (foundInChildren) {
					return foundInChildren;
				}
			}
		}

		// Return null if no route is found
		return null;
	}
}
